import { nextFriday, nextMonday, parseISO } from "date-fns";
import {
  createAbbreviationForTimeZone,
  guessTimeZone,
  isSameOrAfterDay,
  isSameOrBeforeDay,
  isValidTimeZone,
} from "../services/commonUsefulFunctions";

export function isCurrentlyOnTrip(allTrips) {
  return !!getCurrentTrip(allTrips, new Date());
}

export function getCurrentTrip(allTrips, inputDate) {
  const comparisonDate = inputDate ?? new Date();
  return allTrips?.find((trip) => {
    return (
      isSameOrAfterDay(comparisonDate, getTripStartDate(trip)) &&
      isSameOrBeforeDay(comparisonDate, getTripEndDate(trip))
    );
  });
}

export function getTripTimeZone(trip) {
  if (isValidTimeZone(trip?.time_zone)) {
    return trip.time_zone;
  }
  return guessTimeZone();
}

export function getTripTimeZoneAbbreviation(trip) {
  return createAbbreviationForTimeZone(getTripTimeZone(trip));
}

export function getTripStartDate(trip) {
  return getTripRawStartDate(trip)
    ? parseISO(getTripRawStartDate(trip))
    : nextMonday(new Date());
}

function getTripRawStartDate(trip) {
  return trip?.start_date;
}

function getTripRawEndDate(trip) {
  return trip?.end_date;
}

export function getTripEndDate(trip) {
  return getTripRawEndDate(trip)
    ? parseISO(getTripRawEndDate(trip))
    : nextFriday(nextMonday(new Date()));
}
