import React from "react";

import { type IconProps } from "react-feather";

export default function LinkedInLogo({ color="currentColor", size=10, ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1.10525 2.5H1.11825C1.81575 2.5 2.25 2 2.25 1.375C2.237 0.73625 1.81575 0.25 1.1315 0.25C0.44725 0.25 0 0.73625 0 1.375C0 2 0.434 2.5 1.10525 2.5ZM0 3.25H2.25V9.75H0V3.25ZM10 5.625C10 4.31325 8.93675 3.25 7.625 3.25C6.86175 3.25 6.1845 3.6115 5.75 4.171V3.25H3.5V9.75H5.75V6C5.75 5.44775 6.19775 5 6.75 5C7.30225 5 7.75 5.44775 7.75 6V9.75H10C10 9.75 10 5.98875 10 5.625Z" fill={color} />
    </svg>
  );
}
