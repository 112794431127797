import React, { Component }                         from 'react';
import {
  determineTitleWithDuration,
  determineEventDuration,
  expandedDateAndTimeString,
  formatEvent,
  CapitalizeFirstLetterOfEveryWord,
  getUserEmail
}                                                   from '../services/commonUsefulFunctions';
import {
  Clock,
}                                                   from 'react-feather';
import TimeAndTimeZoneText                          from './timeAndTimeZoneText';
import HostDetails from './hostDetails';

class RescheduleLinkInfo extends Component {
  constructor(props) {
    super(props);

    this.state = this.determineState();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.info && prevProps.info && this.props.info.event !== prevProps.info.event) {
      this.setState(this.determineState());
    }
  }

  render() {
    let additionalClassName = this.props.additionalClassName;

    return (
      <div
        className={`availability-event-info ${additionalClassName}`}
        style={this.props.info.isMobile
          ? {width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 15}
          : {paddingTop: 25, paddingBottom: 25, paddingLeft: 30}
        }
      >
        <HostDetails
          hostDisplayName={this.getName()}
          profilePhotoSrc={this.props.info.profile_photo_url}
          socialLinks={this.props.info.social_links}
        />

        {this.state.duration ?
          <div
            className="availability-link-title"
            style={{fontSize: 20}}
          >
            {determineTitleWithDuration(this.state.duration)}
          </div>
        : null}

        <div className={`display-flex-flex-direction-row margin-top-ten align-items-center font-size-14`}>
          <div className="mr-2.5 pt-1 font-size-14">
            <Clock size={16} />
          </div>

          {this.state.duration} minutes
        </div>

        {this.state.displayTime ? this.renderFormerTime() : null}
      </div>
    );
  }

  getName() {
    if (this.isSchedulingForExec()) {
      return CapitalizeFirstLetterOfEveryWord(this.props.info.user?.full_name)
        || getUserEmail(this.props.info.user) || "";
    }

    return CapitalizeFirstLetterOfEveryWord(this.props.info.sender_full_name)
      || CapitalizeFirstLetterOfEveryWord(this.props.info.master_account?.full_name)
      || CapitalizeFirstLetterOfEveryWord(this.props.info.user.full_name)
      || getUserEmail(this.props.info.user);
  }

  isSchedulingForExec() {
    return this.props.info.is_scheduling_for_exec;
  }

  renderFormerTime() {
    let shouldCrossThrough = this.props.info.onClickedConfirmTime;

    return (
      <div
        className="reschedule-former-time-container"
        style={this.props.info.isMobile ? {marginLeft: 0} : {}}
      >
        <div className="font-weight-300">
          Original Time
        </div>

        <TimeAndTimeZoneText shouldCrossThrough={shouldCrossThrough} time={this.state.displayTime} />
      </div>
    );
  }

  determineState() {
    return {
      duration: determineEventDuration(this.props.info.event),
      displayTime: this.createDisplayTime()
    };
  }

  createDisplayTime() {
    if (!this.props.info.event) {
      return null;
    }

    const event = this.props.info.event;

    const formattedEvent = formatEvent(event);

    return expandedDateAndTimeString(formattedEvent);
  }
}

export default RescheduleLinkInfo;
