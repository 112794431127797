import React, { Component }       from 'react';

class RedirectRoute extends Component {

  render() {
    window.location.assign('https://www.vimcal.com/');

    return (
      <div>

      </div>
    );
  }
}


export default RedirectRoute;
