import { format, subDays } from "date-fns";
import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  pluralize,
  createUUID,
  removeDuplicatesFromArray,
  IsEmailValid,
  isEmptyArray,
} from "../../services/commonUsefulFunctions";
import classNames from "classnames";
import "../../styles/groupVoteLinkStyles.css";
import "../../styles/groupVoteDarkModeStyles.css";
import { Check, Plus } from "react-feather";
import {
  parseEventsWithDefaultTimeZone,
  sortSlotsChronologically,
  isMultiDaySlot,
  getSpreadsheetAttendees,
  createKeyFromSlotISOString,
} from "../../lib/availabilityFunctions";
import { isEventSlotAllDayEvent } from "../../lib/rbcFunctions";
import NameEmailDisplay from "./nameEmailDisplay";
import VoteAndCommentContainer from "./voteAndCommentContainer";
import EditAndTrashIcons from "../editAndTrashIcons";
import {
  createCommentDictionary,
  createGroupVoteSpreadsheetSlotAttendeeIndex,
  getAttendeeUUID,
  onToggleGroupVoteSpreadsheetSlot,
  onUpdateGroupVoteSpreadsheetSlotComment,
} from "./sharedFunctions";
import NameAndEmailUnderEdit from "./nameAndEmailUnderEdit";

const HEADER_HEIGHT = "group-vote-date-time-header";

export default function GroupVoteSpreadSheetTable({
  bookingLink,
  selectedTimeZone,
  onDeleteAttendee,
  onUpdateAttendee,
}) {
  const selectedTimeZoneRef = useRef(selectedTimeZone);
  const [uuidNameEmailUnderEdit, setUuidNameEmailUnderEdit] = useState(null);
  const [attendeeList, setAttendeeList] = useState(
    getSpreadsheetAttendees(bookingLink)
  );
  const [emailInvalidUUID, setEmailInvalidUUID] = useState(null);
  const [slotAttendeeIndex, setSlotsAttendeeIndex] = useState(
    createGroupVoteSpreadsheetSlotAttendeeIndex(bookingLink)
  );
  const [commentDictionary, setCommentDictionary] = useState(
    createCommentDictionary(bookingLink)
  );
  const [availableTimes, setAvailableTimes] = useState(
    sortSlotsChronologically(
      parseEventsWithDefaultTimeZone(bookingLink, selectedTimeZone)
    )
  );
  const [hideAttendees] = useState(bookingLink.anonymous);

  useEffect(() => {
    if (selectedTimeZoneRef.current === selectedTimeZone) {
      return;
    }
    selectedTimeZoneRef.current = selectedTimeZone;
    setAvailableTimes(
      sortSlotsChronologically(
        parseEventsWithDefaultTimeZone(bookingLink, selectedTimeZone)
      )
    );
  }, [selectedTimeZone]);

  const renderLeftAttendeesSection = () => {
    return (
      <aside
        className={classNames(
          "group-vote-link-border-right",
          "group-vote-attendees-container"
        )}
      >
        <div
          className={classNames(HEADER_HEIGHT, "group-vote-link-border-bottom")}
        ></div>
        {hideAttendees ? null : (
          <div className="participant-container font-weight-400 font-size-12-important">{`${
            attendeeList.length
          } ${pluralize(attendeeList.length, "attendee")}`}</div>
        )}
        {attendeeList.map((attendee, index) => {
          const isUnderEdit =
            uuidNameEmailUnderEdit === getAttendeeUUID(attendee);
          return (
            <div
              className="relative hoverable-container"
              key={`group-vote-attendee-${getAttendeeUUID(attendee)}`}
            >
              {isUnderEdit ? (
                <NameAndEmailUnderEdit
                  attendee={attendee}
                  onSave={({ name, email }) => {
                    if (!IsEmailValid(email)) {
                      // must have email
                      setEmailInvalidUUID(getAttendeeUUID(attendee));
                      return;
                    }
                    setUuidNameEmailUnderEdit(null); // reset
                    const index = attendeeList.findIndex(
                      (element) =>
                        getAttendeeUUID(element) === getAttendeeUUID(attendee)
                    );

                    if (index === -1) {
                      // should not happen -> bad
                      return;
                    }
                    setAttendeeList(attendeeList => {
                      const updatedAttendeeList = [...attendeeList];
                      const matchingAttendee = updatedAttendeeList[index];
                      updatedAttendeeList.splice(index, 1, {
                        ...matchingAttendee,
                        email,
                        name,
                      });
                      onUpdateAttendee(updatedAttendeeList[index]); // if only change name/email, we also want to update the attendee
                      return updatedAttendeeList;
                    });
                  }}
                  showEmailWarning={
                    emailInvalidUUID === getAttendeeUUID(attendee)
                  }
                  resetWarning={setEmailInvalidUUID}
                />
              ) : (
                <NameEmailDisplay attendee={attendee} index={index} />
              )}
              <div className="absolute top-2 right-2 reveal-on-hover duration-200">
                {isUnderEdit || uuidNameEmailUnderEdit ? null : (
                  <EditAndTrashIcons
                    onClickEdit={() => {
                      setUuidNameEmailUnderEdit(getAttendeeUUID(attendee));
                    }}
                    onClickTrash={() => {
                      setAttendeeList((attendeeList) => {
                        const updatedAttendeeList = attendeeList.filter(
                          (elem) =>
                            getAttendeeUUID(elem) !== getAttendeeUUID(attendee)
                        );
                        onDeleteAttendee(getAttendeeUUID(attendee));
                        return updatedAttendeeList;
                      });
                    }}
                  />
                )}
              </div>
            </div>
          );
        })}
        {renderAddNewRow()}
      </aside>
    );
  };

  const renderAddNewRow = () => {
    const onClickAdd = () => {
      if (uuidNameEmailUnderEdit) {
        return;
      }
      const uuid = createUUID();
      const updatedAttendeeList = attendeeList.concat({
        email: "",
        name: "",
        slots: [],
        uuid,
      });
      setAttendeeList(updatedAttendeeList);
      setUuidNameEmailUnderEdit(uuid);
    };
    return (
      <div
        className="select-none participant-container font-weight-300 font-size-12-important group-vote-link-border-top hoverable-secondary-text-color cursor-pointer"
        onClick={onClickAdd}
      >
        <Plus size={12} className="mr-2"/>
        Add new
      </div>
    );
  };

  const renderDateHeader = (slot) => {
    const {
      month,
      dayOfMonth,
      dayOfWeek,
      endMonth,
      endDayOfMonth,
      endDayOfWeek,
    } = getDateDetails(slot);
    if (isMultiDaySlot(slot)) {
      return (
        <div className="flex items-center w-full justify-center">
          <div className="flex items-center flex-col">
            <div className="mt-2 font-size-14">{month}</div>
            <div className="font-size-14 font-weight-400">{dayOfMonth}</div>
            <div className="font-size-12 font-weight-300">
              {dayOfWeek.toUpperCase()}
            </div>
          </div>

          <div className="mx-1.5">-</div>

          <div className="flex items-center flex-col">
            <div className="mt-2 font-size-14">{endMonth}</div>
            <div className="font-size-14 font-weight-400">{endDayOfMonth}</div>
            <div className="font-size-12 font-weight-300">
              {endDayOfWeek.toUpperCase()}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={classNames("flex flex-col items-center")}>
          <div className="mt-2 font-size-14">{month}</div>
          <div className="font-size-14 font-weight-400">{dayOfMonth}</div>
          <div className="font-size-12 font-weight-300">
            {dayOfWeek.toUpperCase()}
          </div>
        </div>
      );
    }
  };

  const renderTime = (slot) => {
    const { startTime, endTime } = getTimeDetails(slot);
    const isAllDay = isEventSlotAllDayEvent(slot);
    return (
      <div
        className={classNames(
          "display-flex flex-direction-column justify-content-center",
          "font-size-12",
          "mt-2.5"
        )}
      >
        <div className={classNames("display-flex justify-content-center")}>
          {isAllDay ? "All day" : startTime}
        </div>
        <div className="display-flex justify-content-center">
          {isAllDay ? "" : endTime}
        </div>
      </div>
    );
  };

  const renderCount = (slot, index) => {
    if (hideAttendees) {
      return null;
    }

    const key = createKeyFromSlotISOString(slot);
    const TEXT_COLOR = "text-blue-600";

    return (
      <div
        className={classNames(
          "flex items-center",
          "justify-center time-slot-height",
          "cursor-pointer",
          "relative",
          "select-none",
          isEmptyArray(attendeeList) ? "group-vote-link-border-bottom" : "",
        )}
      >
        {/* {renderHoverAttendeeInfo()} */}
        <Check
          className={classNames("mr-1", TEXT_COLOR)}
          size={16}
          strokeWidth={4}
        />
        <div
          className={classNames(TEXT_COLOR, "font-weight-400", "font-size-14")}
        >
          {`${slotAttendeeIndex[key]?.length || 0}`}
        </div>
      </div>
    );
  };

  const renderNonMobileSlotSection = (slot, index) => {
    return (
      <li
        key={`slots-container-${index}`}
        className={classNames(
          "slot-container",
          index === 0 ? "" : "group-vote-link-border-left"
        )}
      >
        <div
          className={classNames("group-vote-link-border-bottom", HEADER_HEIGHT)}
        >
          {renderDateHeader(slot)}
          {renderTime(slot)}
        </div>
        {renderCount(slot, index)}

        {attendeeList.map((attendee, attendeeIndex) => {
          const isMarked = hasAttendeeMarkedTime({
            attendee,
            slot,
            slotAttendeeIndex,
          });
          const comment = getAttendeeComment({
            attendee,
            slot,
            commentDictionary,
            selectedTimeZone,
          });

          const onClickMark = () => {
            const updatedIsMarked = !isMarked;
            const updatedSlotsAttendeeIndex = { ...slotAttendeeIndex };
            const key = createKeyFromSlotISOString(slot);
            const uuid = getAttendeeUUID(attendee);
            if (updatedIsMarked) {
              // selected
              if (!updatedSlotsAttendeeIndex[key]) {
                updatedSlotsAttendeeIndex[key] = [uuid];
              } else {
                updatedSlotsAttendeeIndex[key] = removeDuplicatesFromArray(
                  slotAttendeeIndex[key].concat([uuid])
                );
              }
            } else {
              // unselected
              if (updatedSlotsAttendeeIndex[key]) {
                updatedSlotsAttendeeIndex[key] = updatedSlotsAttendeeIndex[
                  key
                ].filter((id) => id !== uuid);
              }
            }
            setSlotsAttendeeIndex(updatedSlotsAttendeeIndex);
            setAttendeeList((attendeeList) => {
              const updatedAttendeeList = onToggleGroupVoteSpreadsheetSlot({
                slot,
                attendeeList,
                attendee,
                selectedTimeZone,
                isSelected: updatedIsMarked,
              });
              onUpdateAttendee(
                updatedAttendeeList.find(
                  (a) => getAttendeeUUID(a) === getAttendeeUUID(attendee)
                ),
              );
              return updatedAttendeeList;
            });
          };

          return (
            <div
              key={`attendee-booking-mark-${getAttendeeUUID(
                attendee
              )}-${index}`}
              className={classNames(
                "spreadsheet-participant-mark-container",
                "group-vote-link-border-top",
                isMarked ? "selected-faded-background-color" : "",
                attendeeIndex === attendeeList.length - 1
                  ? "group-vote-link-border-bottom"
                  : ""
              )}
            >
              <VoteAndCommentContainer
                isMarked={isMarked}
                comment={comment}
                onClick={onClickMark}
                onChangeComment={(e) => {
                  const updatedComment = e.target.value;
                  updateComment({
                    updatedComment,
                    attendee,
                    slot,
                  });
                  const updatedAttendeeList =
                    onUpdateGroupVoteSpreadsheetSlotComment({
                      slot,
                      attendeeList,
                      attendee,
                      comment: updatedComment,
                    });
                  setAttendeeList(updatedAttendeeList);
                  onUpdateAttendee(
                    updatedAttendeeList.find(
                      (a) => getAttendeeUUID(a) === getAttendeeUUID(attendee)
                    ),
                  );
                }}
              />
            </div>
          );
        })}
      </li>
    );
  };

  const updateComment = ({ updatedComment, attendee, slot }) => {
    const updatedCommentDictionary = { ...commentDictionary };
    const uuid = getAttendeeUUID(attendee);
    const key = createKeyFromSlotISOString(slot);
    if (!updatedCommentDictionary[uuid]) {
      updatedCommentDictionary[uuid] = {
        [key]: updatedComment,
      };
    } else {
      updatedCommentDictionary[uuid][key] = updatedComment;
    }
    setCommentDictionary(updatedCommentDictionary);
  };

  const timeTable = useMemo(() => {
    // only call render here if available times or new times are picked
    // more performant
    return (
      <ul className="flex">
        {availableTimes.map((t, index) => {
          return renderNonMobileSlotSection(t, index);
        })}
      </ul>
    );
  }, [
    availableTimes,
    commentDictionary,
    slotAttendeeIndex,
    attendeeList,
    emailInvalidUUID,
  ]);

  return (
    <div
      className={classNames(
        "spreadsheet-table-container",
        "inline-flex flex-row",
        "default-border",
      )}
    >
      {renderLeftAttendeesSection()}
      {timeTable}
    </div>
  );
}

function getTimeDetails(slot) {
  const { eventStart, eventEnd } = slot;

  return {
    startTime: format(eventStart, "p"),
    endTime: format(eventEnd, "p"),
  };
}

function getDateDetails(slot) {
  const { eventStart, eventEnd } = slot;

  if (isMultiDaySlot(slot)) {
    const updatedEnd = subDays(eventEnd, 1);
    return {
      month: format(eventStart, "MMM"),
      dayOfMonth: format(eventStart, "d"),
      dayOfWeek: format(eventStart, "E"),
      endMonth: format(updatedEnd, "MMM"),
      endDayOfMonth: format(updatedEnd, "d"),
      endDayOfWeek: format(updatedEnd, "E"),
    };
  }

  return {
    month: format(eventStart, "MMM"),
    dayOfMonth: format(eventStart, "d"),
    dayOfWeek: format(eventStart, "E"),
    endMonth: format(eventEnd, "MMM"),
    endDayOfMonth: format(eventEnd, "d"),
    endDayOfWeek: format(eventEnd, "E"),
  };
}

function hasAttendeeMarkedTime({ attendee, slot, slotAttendeeIndex }) {
  const key = createKeyFromSlotISOString(slot);
  return slotAttendeeIndex[key]?.includes(getAttendeeUUID(attendee));
}

function getAttendeeComment({
  attendee,
  slot,
  commentDictionary,
  selectedTimeZone,
}) {
  const uuid = getAttendeeUUID(attendee);
  const key = createKeyFromSlotISOString(slot);
  return commentDictionary[uuid]?.[key] || "";
}
