import React from "react";
import ContactHandle from "./contactHandle";
import XLogo from "../icons/xLogo";

export default function TwitterHandle() {
  return (
    <ContactHandle>
      <XLogo size={11} />
    </ContactHandle>
  )
}
