import { Component } from "react";
import {
  GOOGLE_PLACES_ID,
  GOOGLE_PLACES_SCRIPT_SRC,
} from "../lib/googleFunctions";
import {
  doesGooglePlacesScriptExist,
  handleError,
} from "../services/commonUsefulFunctions";
import { SECOND_IN_MS } from "../services/globalVariables";

export default class BackendContainer extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
    this._googleScriptLoadedFailed = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadGooglePlacesScript();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return null;
  }

  async loadGooglePlacesScript(loadCount = 0) {
    if (loadCount > 5) {
      this._googleScriptLoadedFailed = true;
      return;
    }

    let script;
    let resolveLoadNewScript; // so we can remove it properly. Otherwise, if it's normal arrow function `const a = () => {}`, it won't be able to remove it becasue there's no reference
    let rejectNewScript;

    // Helper functions below
    const loadScript = () => {
      return new Promise((resolve, reject) => {
        script = document.createElement("script");

        script.src = GOOGLE_PLACES_SCRIPT_SRC;
        script.async = true;
        script.defer = true;
        script.id = GOOGLE_PLACES_ID;

        resolveLoadNewScript = () => {
          resolve();
        };

        rejectNewScript = (e) => {
          // goes into catch
          reject(e);
        };

        script.addEventListener("load", resolveLoadNewScript);
        script.addEventListener("error", rejectNewScript);

        if (doesGooglePlacesScriptExist()) {
          // more of sanity check so we don't append another script
          // check if it already exists
          resolve();
          return; // early return;
        }
        document.body.appendChild(script);
      });
    };

    const removeScriptListeners = () => {
      if (!script) {
        return;
      }
      if (resolveLoadNewScript) {
        script.removeEventListener("load", resolveLoadNewScript);
      }

      if (rejectNewScript) {
        script.removeEventListener("error", rejectNewScript);
      }
      script = null;
      resolveLoadNewScript = null;
      rejectNewScript = null;
    };

    const reloadScript = () => {
      removeScriptListeners();
      setTimeout(() => {
        if (!this._isMounted) {
          return;
        }
        this.loadGooglePlacesScript(loadCount + 1); // try again
      }, SECOND_IN_MS * (loadCount + 1)); // try again in a few sec
    };
    // Helper functions above

    // Main logic below
    if (doesGooglePlacesScriptExist()) {
      // already exists
      return;
    }

    try {
      await loadScript();
      if (!this._isMounted) {
        return;
      }

      removeScriptListeners(); // script has been loaded
    } catch (error) {
      handleError(error);
      if (!this._isMounted) {
        return;
      }

      removeScriptListeners();
      // Try again
      if (loadCount <= 5) {
        reloadScript();
      } else {
        this._googleScriptLoadedFailed = true;
      }
    }
  }
}
