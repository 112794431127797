import classNames from "classnames";
import React from "react";
import { Check } from "react-feather";

const MAX_INPUT_LENGTH = 110;
export default function VoteAndCommentContainer({
  isMarked,
  comment,
  onClick,
  onChangeComment,
}) {
  return (
    <div className={classNames()}>
      <div className="w-full cursor-pointer" onClick={onClick}>
        {isMarked ? renderMarked() : renderUnmarked()}
      </div>
      
      <textarea
        className={classNames(
          "border-transparent",
          "bg-transparent",
          "mt-1",
          "resize-none",
          "font-size-12 font-weight-300",
          // "height-84px max-height-84px",
          "h-16 max-h-16",
          "overflow-hidden"
        )}
        onChange={(e) => {
          if (e.target.value.length + 1 > MAX_INPUT_LENGTH) {
            return;
          }
          onChangeComment(e);
        }}
        value={comment || ""}
        placeholder={"Add note..."}
      />
    </div>
  );
}

function renderMarked() {
  return (
    <button
      className={classNames(
        "rounded",
        "w-4 h-4",
        "cursor-pointer",
        "bg-white",
        "flex items-center justify-center",
        "border-solid-1px",
        "duration-100",
        "selected-checkbox-blue"
      )}
    >
      <Check className="text-white" size="10" strokeWidth={4} />
    </button>
  );
}

function renderUnmarked() {
  return (
    <button
      className={classNames(
        "rounded",
        "w-4 h-4",
        "cursor-pointer",
        "bg-white",
        "flex items-center justify-center",
        "unselected-checkbox-border",
        "duration-100"
      )}
    ></button>
  );
}
