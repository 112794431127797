import React from "react";
import { SLOTS_GIF } from "../services/globalVariables";

export default function SlotsDemoGif() {
  return (
    <img
      alt=""
      className="slots-gif-container rounded-xl mt-5"
      src={SLOTS_GIF}
    />
  );
}
