export const DEFAULT_FONT_COLOR = "#4E516A";
export const LIGHT_GRAY = "#E0E0E0";
export const LIGHT_FADED_GRAY = "#F1F3F4";
export const MEDIUM_GRAY = "#A1A1A1";
export const AVAILABLE_DAY_MOMENT_FORMAT = "dddd LL";
export const UTC_TIME_ZONE = "UTC";
export const VIMCAL_RICH_TEXT_SIGNATURE =
  'Created with <a href="https://www.vimcal.com/" rel="noopener noreferrer" target="_blank">Vimcal.com</a>';
export const VIMCAL_LOGO =
  "https://vimcal-email-assets.s3.us-west-1.amazonaws.com/logos/circle%402x-dark.png";
export const MOBILE_WIDTH_LIMIT = 600;
export const DARK_PURPLE = "#859BF3";
export const LIGHT_PURPLE = "#F5F6FB";
export const HOVER_PURPLE = "#E9ECF8";
export const DEFAULT_BLUE = "#176EFF";
export const LIGHT_BLUE = "#E0EBFD";
export const HOVER_BLUE = "#1D4ED8";

export const OUTLOOK_PROVIDER = "outlook";
export const GOOGLE_PROVIDER = "google";

export const MOBILE_CONTAINER_STYLE = {
  borderRadius: 0,
  width: "100vh",
  height: "100%",
  justifyContent: "center",
};

export const BACKEND_ZOOM = "zoom";
export const BACKEND_HANGOUT = "google_hangouts";
export const BACKEND_NO_CONFERENCING = "none";
export const BACKEND_PHONE = "phone";
export const BACKEND_WHATS_APP = "whatsapp";
export const TIME_FORMAT = "h:mma";
export const BACKEND_CUSTOM_CONFERENCING = "custom";

// Outlook
export const TEAMS_FOR_BUSINESS_LABEL = "Teams";
export const SKYPE_FOR_BUSINESS_LABEL = "Skype for Business";
export const SKYPE_FOR_CONSUMER_LABEL = "Skype for Consumer";
export const BACKEND_SKYPE_FOR_BUSINESS = "skypeForBusiness";
export const BACKEND_SKYPE_FOR_CONSUMER = "skypeForConsumer";
export const BACKEND_TEAMS_FOR_BUSINESS = "teamsForBusiness";
export const OUTLOOK_CONFERENCING = {
  skypeForBusiness: BACKEND_SKYPE_FOR_BUSINESS,
  skypeForConsumer: BACKEND_SKYPE_FOR_CONSUMER,
  teamsForBusiness: BACKEND_TEAMS_FOR_BUSINESS,
};
export const microsoftTeamsIconURL =
  "https://vimcal-email-assets.s3-us-west-1.amazonaws.com/1200px-Microsoft_Office_Teams_(2018%E2%80%93present).svg.png";
export const SKYPE_ICON_URL =
  "https://vimcal-email-assets.s3.us-west-1.amazonaws.com/skype-consumer-50.png";
export const SKYPE_FOR_BUSINESS_ICON =
  "https://vimcal-email-assets.s3.us-west-1.amazonaws.com/skype-for-business-50px.png";

export const ZOOM_LOGO_URL =
  "https://lh3.googleusercontent.com/ugWKRyPiOCwjn5jfaoVgC-O80F3nhKH1dKMGsibXvGV1tc6pGXLOJk9WO7dwhw8-Xl9IwkKZEFDbeMDgnx-kf8YGJZ9uhrJMK9KP8-ISybmbgg1LK121obq2o5ML0YugbWh-JevWMu4FxxTKzM2r68bfDG_NY-BNnHSG7NcOKxo-RE7dfObk3VkycbRZk_GUK_1UUI0KitNg7HBfyqFyxIPOmds0l-h2Q1atWtDWLi29n_2-s5uw_kV4l2KeeaSGws_x8h0zsYWLDP5wdKWwYMYiQD2AFM32SHJ4wLAcAKnwoZxUSyT_lWFTP0PHJ6PwETDGNZjmwh3hD6Drn7Z3mnX662S6tkoPD92LtMDA0eNLr6lg-ypI2fhaSGKOeWFwA5eFjds7YcH-axp6cleuiEp05iyPO8uqtRDRMEqQhPaiRTcw7pY5UAVbz2yXbMLVofojrGTOhdvlYvIdDgBOSUkqCshBDV4A2TJyDXxFjpSYaRvwwWIT0JgrIxLpBhnyd3_w6m5My5QtgBJEt_S2Dq4bXwCAA7VcRiD61WmDyHfU3dBiWQUNjcH39IKT9V1fbUcUkfDPM_AGjp7pwgG3w9yDemGi1OGlRXS4pU7UwF24c2dozrmaK17iPaExn0cmIgtBnFUXReY48NI8h2MNd_QysNMWYNYbufoPD7trSu6nS39wlUDQer2V";
export const HANGOUT_LOGO_URL =
  "https://lh5.googleusercontent.com/proxy/bWvYBOb7O03a7HK5iKNEAPoUNPEXH1CHZjuOkiqxHx8OtyVn9sZ6Ktl8hfqBNQUUbCDg6T2unnsHx7RSkCyhrKgHcdoosAW8POQJm_ZEvZU9ZfAE7mZIBGr_tDlF8Z_rSzXcjTffVXg3M46v";
export const PHONE_MEETING_ICON =
  "https://lh3.googleusercontent.com/pw/ACtC-3edDKxOEVFC6VET34hGZ6N-7AmFdJdyQEB9ua8lMUECnxPNAwwYif1hddMg5WuoE1YHvxNPkq7fkPNS1ZoszY6htoZ0ZdKxvOOdD8GwNPI2htuq9kLB4syX_3OAbmI8Qs5tKAPqBxjP7MRF-kREpw=s64-no";
export const WHATS_APP_ICON =
  "https://lh3.googleusercontent.com/proxy/zI_doeNVnb8WEpnWL6_GmKFTbDVbfmVZ6vseOC5YC9ObACzZ_Eyyp15Cw5MMLvS3Yr0kOasyO5Lgrsh6F3qZdy2201dywiO2rOx0kjm2GbxY_mS8SPs6rlBrSKMSNkX4Xq4b0_6H=s0-d";

export const COLORED_VIMCAL_LOGO =
  "https://vimcal-app-images.s3.amazonaws.com/gradient-vimcal-logo.png";

export const BLUE_BUTTON = "blue-button";
export const RED_BUTTON = "red-button";
export const WHITE_BUTTON = "white-button";
export const DEFAULT_BUTTON = "default-button";
export const STATUS_CANCELLED = "cancelled";

export const BACKEND_AVAILABILITY = "availability";
export const BACKEND_PERSONAL = "personal";
export const NEEDS_ACTION_STATUS = "needsAction";
export const ACCEPTED_STATUS = "accepted";
export const ZOOM_PERSONAL_LINK = "personal_link";
export const ZOOM_UNIQUE = "unique_meeting_ids";
export const SELECT_AVAILABILITY_COLOR = "#50E3C2";
export const SECOND_IN_MS = 1000;

export const INVITEE_NAME_BLOCK = "{{Invitee_name}}";
export const INVITEE_COMPANY_NAME = "{{Company_name}}";
export const ALL_BOOKING_VARIABLES = {
  INVITEE_NAME_BLOCK: INVITEE_NAME_BLOCK,
  INVITEE_COMPANY_NAME: INVITEE_COMPANY_NAME,
};

export const DOWNLOAD_IOS_LINK = "https://apps.apple.com/us/app/vimcal-calendar/id1608841561";

export const LOGIN_ICONS = {
  GOOGLE:
    "https://vimcal-email-assets.s3-us-west-1.amazonaws.com/google_icon.png",
  OUTLOOK: "https://vimcal-app-images.s3.amazonaws.com/Microsoft_logo.svg.png",
  IOS: "https://vimcal-app-images.s3.amazonaws.com/app+store+icon.png"
};

export const SECONDARY_TEXT_COLOR = "#A8A8A8";
export const SLOTS_GIF = "https://vimcal-email-assets.s3.us-west-1.amazonaws.com/availability.gif";
