import * as Sentry from "@sentry/browser";
import {
  sendBreadcrumbToSentry,
  breadcrumbData,
  handleError,
} from "./commonUsefulFunctions";

const parseJSON = (response) => (response ? response.json() : {});

const vimcalFetch = (path, params, method, authorizationRequired = true) => {
  sendBreadcrumbToSentry({
    category: "Fetch",
    message: path,
    data: {
      params: breadcrumbData(params),
      method,
      authorizationRequired,
    },
  });
  return fetch(path, constructParams(params, method, authorizationRequired));
};

const FETCHER_ACTION = {
  GET: "GET",
  POST: "POST",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

const Fetcher = {
  get: (path, params = {}, authorizationRequired = false) =>
    vimcalFetch(path, params, FETCHER_ACTION.GET, authorizationRequired)
      .then(parseJSON)
      .catch((err) => {
        handleError(err);
      }),
  post: (path, params = {}, authorizationRequired = false) =>
    vimcalFetch(path, params, FETCHER_ACTION.POST, authorizationRequired)
      .then(parseJSON)
      .catch((err) => {
        handleError(err);
      }),
  patch: (path, params = {}, authorizationRequired = false) =>
    vimcalFetch(path, params, FETCHER_ACTION.PATCH, authorizationRequired)
      .then(parseJSON)
      .catch((err) => {
        handleError(err);
      }),
  delete: (path, params = {}, authorizationRequired = false) =>
    vimcalFetch(path, params, FETCHER_ACTION.DELETE, authorizationRequired)
      .then(parseJSON)
      .catch((err) => {
        handleError(err);
      }),
};

const constructParams = (params, verb, authorizationRequired) => {
  if (!params["headers"]) {
    params["headers"] = {};
  }

  params["method"] = verb;
  params["headers"]["Content-Type"] = "application/json";

  let currentUser = null;

  if (authorizationRequired && currentUser) {
    params["headers"]["X-VIMCAL-USER-EMAIL"] = currentUser;
    params["credentials"] = "include";
  }

  return params;
};

export default Fetcher;
