import React from "react";

import { type IconProps } from "react-feather";

export default function XLogo({ color="currentColor", size=10 }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.95249 4.23476L9.67562 0H8.79347L5.55922 3.67622L2.97792 0H0L3.90431 5.55959L0 10H0.882144L4.2955 6.11688L7.02209 10H10L5.95249 4.23476ZM4.74387 5.60839L4.34768 5.05444L1.20027 0.650732H2.55542L5.09623 4.20598L5.49075 4.75994L8.79305 9.38097H7.4379L4.74387 5.60839Z" fill={color} />
    </svg>
  );
}
