import classNames from 'classnames';
import React, { Component }                         from 'react';


class GeneralErrorMessage extends Component {
  render() {
    const { children, message, title, titleStyle, containerClassName } = this.props;

    return (
      <div className={classNames(containerClassName || "availability-error-container h-full")}>
        {this.renderUserName()}
        <div style={titleStyle || {fontSize: 24, marginBottom: 5}}>
            {title || 'Oh no!'}
        </div>

        <div style={{fontSize: 14, fontWeight: 300, marginBottom: 70}}>
          {children || message || 'An error has occurred!'}
        </div>
      </div>
    );
  }

  renderUserName() {
    const {
      user,
      userNameClassName
    } = this.props;
    if (!user?.full_name) {
      return null;
    }
    return (
      <div className={classNames(userNameClassName || "font-weight-500 font-size-24px mb-2")}>
        {user.full_name}
      </div>
    )
  }
}

export default GeneralErrorMessage
