import React from "react";
import { DOWNLOAD_IOS_LINK, LOGIN_ICONS } from "../services/globalVariables";
import classNames from "classnames";
import { OpenLink } from "../services/commonUsefulFunctions";
import { trackConfirmationPageDownload } from "../lib/tracking";

export default function DownloadIOSButton() {
  const onClickLogin = () => {
    trackConfirmationPageDownload("ios");
    OpenLink(DOWNLOAD_IOS_LINK);
  };
  return (
    <div
      style={{
        height: "43.5px",
        color: "rgba(0, 0, 0, 0.54)",
        width: "190px",
      }}
      className={classNames(
        "bg-white duration-200 rounded-md flex justify-center items-center text-center default-font-size font-weight-400 cursor-pointer my-2 login-button-dimension mb-2 select-none",
        "rounded-md",
        "font-size-12",
        "white-button-border"
      )}
      onClick={onClickLogin}
    >
      <img
        alt=""
        width="18px"
        height="18px"
        className="mr-2 ml-1"
        src={LOGIN_ICONS.IOS}
      />
      Download on iOS
    </div>
  );
}
