import classNames from "classnames";
import React from "react";
import SchedulingCheckMark from './schedulingCheckMark';

export default function ScheduleCheckBox({ isChecked, defaulBorder, onChange, size }) {
  return (
    <button
      className={classNames(
        "border-width-1",
        "rounded",
        size ?? "w-6 h-6",
        "cursor-pointer",
        "bg-white",
        "flex items-center justify-center",
        "border-2",
        isChecked ? "border-blue-500" : defaulBorder || "border-transparent",
        "duration-100",
      )}
      onClick={onChange}
    >
      {isChecked ? (
        <SchedulingCheckMark />
      ) : null}
    </button>
  );
}
