import React, { PureComponent }               from 'react';
import {
  BLUE_BUTTON,
  WHITE_BUTTON,
  RED_BUTTON,
  DEFAULT_BUTTON
}                                             from '../services/globalVariables';
import Classnames                             from 'classnames';
import { isMac }                              from '../services/commonUsefulFunctions';


class CustomButton extends PureComponent {

  componentDidMount() {
    if (this.props.shouldFocus) {
      this.customButton.focus();
    }
  }

  render() {
    return (
      <button
        className={this.determineClassName()}
        onClick={this.props.onClick}
        ref={ref => {this.customButton = ref}}
        tabIndex={this.props.buttonTabIndex}
        style={Object.assign(
          {},
          this.props.style
        )}
      >
        <div
          className={isMac() ? "button-label-text" : "button-label-text-pc"}
          style={Object.assign(
            {},
            this.props.labelStyle
          )}
        >
          {this.props.label}
        </div>
      </button>
    );
  }

  determineClassName() {
    let defaultClassName = 'custom-button-box select-none';

    if (this.props.addPaddingToRight) {
      defaultClassName = defaultClassName + ' margin-right-10';
    }

    if (this.props.buttonType === BLUE_BUTTON) {
      return Classnames(`${defaultClassName} ${BLUE_BUTTON} ${this.props.className}`);
    } else if (this.props.buttonType === WHITE_BUTTON) {
      return Classnames(`${defaultClassName} ${WHITE_BUTTON} ${this.props.className}`);
    } else if (this.props.buttonType === RED_BUTTON) {
      return Classnames(`${defaultClassName} ${RED_BUTTON} ${this.props.className}`);
    } else {
      return Classnames(`${defaultClassName} ${DEFAULT_BUTTON} ${this.props.className}`);
    }
  }
}


export default CustomButton;
