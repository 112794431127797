import React, { Component }                         from 'react';
import {
  getVimcalURLWithAttribution, 
  OpenLink
}                                                   from '../services/commonUsefulFunctions';
import HoverableLogo from './hoverableLogo';


class VimcalLogoWithMessage extends Component {
  constructor(props) {
    super(props);

    this.goToVimcalWebsite = this.goToVimcalWebsite.bind(this);
  }

  render() {
    return <HoverableLogo />
  }

  goToVimcalWebsite() {
    OpenLink(getVimcalURLWithAttribution());
  }
}

export default VimcalLogoWithMessage;
