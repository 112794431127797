import React                      from 'react';
import ReactDOM                   from 'react-dom';
import './js/styles/tailwind.css';
import './index.css';
import App                        from './App';
import * as serviceWorker         from './serviceWorker';
import * as Sentry                from '@sentry/browser';
import { RewriteFrames }          from '@sentry/integrations';

Sentry.init({
  dsn: (process.env.REACT_APP_CLIENT_ENV === 'production' || process.env.REACT_APP_CLIENT_ENV === 'staging') ? "https://b47ddbd063f24fa2a9045a947bc428ef@sentry.io/2190664" : '',
  environment: process.env.REACT_APP_CLIENT_ENV,
  integrations: [new RewriteFrames()]
});



ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
