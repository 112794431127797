import { usePreloadImages } from "../customHooks/usePreloadImages";
import { LOGIN_ICONS, SLOTS_GIF } from "../services/globalVariables";

const DEFAULT_RESOURECES = [
  LOGIN_ICONS.GOOGLE,
  LOGIN_ICONS.OUTLOOK,
  LOGIN_ICONS.IOS,
  SLOTS_GIF
];
export default function PreloadResources({ resources }) {
  usePreloadImages(resources ?? DEFAULT_RESOURECES);
  return null;
}
