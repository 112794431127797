export const GIF_GROUPS = {
  SHOW_GIF: "showGif",
  HIDE_GIF: "hideGif",
};

export function getGifGroup({ name, email }) {
  const combinedString = `${name}_${email}`;
  return bucketString(combinedString);
}

function simpleHash(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

function bucketString(str) {
  const hash = simpleHash(str);
  return hash % 2 === 0 ? GIF_GROUPS.SHOW_GIF : GIF_GROUPS.HIDE_GIF;
}
