import classNames from "classnames";
import React from "react";

export default function NameEmailDisplay({ attendee }) {
  const { name, email } = attendee;
  if (!name || !email) {
    // added a check for !email as a sanity check. Should never get here
    return (
      <div className="spreadsheet-participant-container group-vote-link-border-top pr-2.5">
        <div className="truncate-text max-width-160px font-size-12">
          {email || name || ""}
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        "spreadsheet-participant-container group-vote-link-border-top pr-2.5",
        "flex flex-col justify-center"
      )}
      style={{ alignItems: "flex-start" }}
    >
      <div className="truncate-text max-width-160px font-size-12">{name}</div>
      <div className="truncate-text max-width-160px font-size-12 secondary-text-color mt-2">
        {email}
      </div>
    </div>
  );
}
