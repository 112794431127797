import React, { useState } from "react";
import classNames from "classnames";
import LoadingSkeleton from "./loadingSkeleton";

interface ProfilePhotoProps {
  src: string
}

export default function ProfilePhoto({ src }: ProfilePhotoProps) {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="availability-profile-photo">
      <LoadingSkeleton className={classNames("duration-200 z-10", isLoading ? "opacity-1" : "opacity-0")} />
      <img onLoad={() => setIsLoading(false)} src={src} />
    </div>
  );
}
