import classNames from "classnames";
import React, { useState } from "react";
import { Edit, Trash } from "react-feather";
import { getTooltipStyles } from "../lib/tooltipFunctions";

const SIZE = 12;
const SHARED_CLASS_NAMES =
  "cursor-pointer hoverable-secondary-text-color duration-200";
export default function EditAndTrashIcons({ onClickTrash, onClickEdit }) {
  return (
    <div className="flex gap-2">
      <EditButton onClick={onClickEdit} />
      <TrashButton onClick={onClickTrash} />
    </div>
  );
}

function EditButton({ onClick }) {
  const [hoveredElement, setHoveredElement] = useState(null);
  const [tooltipElement, setTooltipElement] = useState(null);

  const tooltipStyles = getTooltipStyles({
    hoveredElement,
    tooltipElement,
    wrapperElement: null,
    placement: "bottom",
    gap: 6,
    wrapperPadding: 5,
  });
  return (
    <div ref={(element) => setHoveredElement(element)} className="w-max relative hoverable-container">
      <div
        ref={(element) => setTooltipElement(element)}
        className="default-font-size tool-tip-container absolute"
        style={tooltipStyles}
      >
        {"Edit name and email"}
      </div>
      <Edit
        size={SIZE}
        className={classNames(SHARED_CLASS_NAMES)}
        onClick={onClick}
      />
    </div>
  );
}

function TrashButton({ onClick }) {
  const [hoveredElement, setHoveredElement] = useState(null);
  const [tooltipElement, setTooltipElement] = useState(null);

  const tooltipStyles = getTooltipStyles({
    hoveredElement,
    tooltipElement,
    wrapperElement: null,
    placement: "bottom",
    gap: 6,
    wrapperPadding: 5,
  });
  return (
    <div ref={(element) => setHoveredElement(element)} className="w-max relative hoverable-container">
      <div
        ref={(element) => setTooltipElement(element)}
        className="default-font-size tool-tip-container absolute"
        style={tooltipStyles}
      >
        {"Remove row"}
      </div>
      <Trash
        size={SIZE}
        className={classNames(SHARED_CLASS_NAMES)}
        onClick={onClick}
      />
    </div>
  );
}
