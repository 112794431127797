import React from "react";
import classnames from "classnames";
import { format, isSameDay } from "date-fns";

export default function StatelessCalendarDayHeader({ date }) {
  const determineDayOfMonthBackgroundColor = () => {
    if (isSameDay(date, new Date())) {
      return "agenda-hover-blue-background-color";
    } else {
      return "bg-transparent";
    }
  };

  const isToday = isSameDay(new Date(), date);

	const determineDayOfWeekFontColor = () => {
    if (isToday) {
      return 'agenda-hover-blue';
    } else {
      return "default-font-color";
    }
  }

  return (
    <div
      className={classnames(
        "cursor-default",
        "border-none",
        "pointer-events-none",
        "display-flex",
        "flex-direction-column",
        "justify-content-center",
        "align-items-center",
        "padding-top-15",
      )}
    >
      <div
        className={classnames(
          "font-size-10",
          "w-8",
          "h-2.5",
          "font-weight-400",
          "font",
          "cursor-default",
          determineDayOfWeekFontColor(),
          "transition-monthly-agenda-date",
          "leading-normal",
					"pointer-events-none"
        )}
      >
        {format(date, "ccc").toUpperCase()}
      </div>

      <div
        className={classnames(
          "mt-1.5",
          "rounded",
          "display-flex",
          "justify-content-center",
          "align-items-center",
					"cursor-default",
          determineDayOfMonthBackgroundColor(),
          "transition-monthly-agenda-date",
          "font-size-20",
          "font-weight-300",
          "custom-day-header-month",
          isToday ? "text-white" : "default-font-color",
					"pointer-events-none"
        )}
      >
        <div
          className={classnames(
						"pointer-events-none",
            "font-size-16"
          )}
        >
          {format(date, "d")}
        </div>
      </div>
    </div>
  );
}
