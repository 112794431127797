const TOP = "top";

interface GetTooltipStylesOptions {
  hoveredElement: HTMLElement | null;
  tooltipElement: HTMLElement | null;
  wrapperElement?: HTMLElement | null;
  placement: "top" | "bottom";
  gap?: number;
  wrapperPadding?: number;
}

/**
 * Generate the styles needed to place a tooltip relative to the hovered element.
 *
 * Requirements:
 * - The tooltip element must be a child of the hovered element.
 * - The hovered element must have a style of `position: absolute` or `position: relative`.
 * - The tooltip cannot have the style `display: none`. Use opacity or visibility instead.
 *   Disable pointer events since transparent and hidden elements can still be clicked on.
 *
 * TODO: It might be helpful to refactor this into a component that handles the requirements
 * above. Not a blocker though.
 *
 * TODO: Add support for left and right placement.
 *
 * @param options.hoveredElement - The element the user is hovering on to make the tooltip appear.
 * @param options.tooltipElement - The tooltip element itself.
 * @param options.placement - Where the tooltip should be placed relative to the hovered element.
 * @param options.wrapperElement (Optional) The wrapping element that the tooltip should not extend past. Defaults to the full window.
 * @param options.gap - (Optional) The space in pixels between the hovered element and the tooltip. Defaults to 0.
 * @param options.wrapperPadding - (Optional) the space in pixels between the wrapper element and the tooltip. Defaults to 0.
 */
export function getTooltipStyles({
  hoveredElement,
  tooltipElement,
  wrapperElement,
  placement,
  gap = 0,
  wrapperPadding = 0,
}: GetTooltipStylesOptions): React.CSSProperties {
  if (!hoveredElement || !tooltipElement) {
    return {};
  }

  const hoveredBoundingBox = hoveredElement.getBoundingClientRect();
  const tooltipBoundingBox = tooltipElement.getBoundingClientRect();
  const wrapperBoundingBox =
    wrapperElement?.getBoundingClientRect() ??
    new DOMRect(0, 0, window.innerWidth, window.innerHeight);

  const commonStyles: React.CSSProperties = { position: "absolute" };

  // Center the tooltip along the vertical axis.
  let left = (hoveredBoundingBox.width - tooltipBoundingBox.width) / 2;

  // Move to the left if it's extending past the right edge of the wrapper.
  left = Math.min(
    left,
    wrapperBoundingBox.right -
      wrapperPadding -
      hoveredBoundingBox.right -
      tooltipBoundingBox.width +
      hoveredBoundingBox.width
  );

  // Move to the right if it's extending past the left edge of the wrapper.
  left = Math.max(
    left,
    wrapperBoundingBox.left + wrapperPadding - hoveredBoundingBox.left
  );

  const topForTopPlacement = -1 * (gap + tooltipBoundingBox.height);
  const topForBottomPlacement = hoveredBoundingBox.height + gap;

  const topPriority =
    placement === TOP
      ? [topForTopPlacement, topForBottomPlacement]
      : [topForBottomPlacement, topForTopPlacement];

  const tooltipWithinWrapper = (top: number) => {
    return (
      hoveredBoundingBox.top + top > wrapperBoundingBox.top &&
      hoveredBoundingBox.top + top + tooltipBoundingBox.height <
        wrapperBoundingBox.bottom
    );
  };

  // If the preferred placement is completely within the wrapper, use that option.
  if (tooltipWithinWrapper(topPriority[0])) {
    return { ...commonStyles, top: topPriority[0], left };
  }

  // Check the opposite placement if the preferred placement doesn't fit.
  if (tooltipWithinWrapper(topPriority[1])) {
    return { ...commonStyles, top: topPriority[1], left };
  }

  // If neither option fits, use the preferred placement.
  return { ...commonStyles, top: topPriority[0], left };
}
