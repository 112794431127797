import { startOfMinute, differenceInDays, isSameMinute } from "date-fns";
import overlap from "rbc-fork-react-big-calendar/lib/utils/layout-algorithms/overlap";
import { dateFnsLocalizer } from "rbc-fork-react-big-calendar";
import { parse, startOfWeek, getDay, format } from "date-fns";
import { MOMENT_DMY_DATE_FORMAT } from "../services/googleCalendarService";

export function isSelectedSlotAllDayEvent(slot) {
  let { start, end } = slot;

  let eventStart = startOfMinute(start);
  let eventEnd = startOfMinute(end);
  return differenceInDays(eventEnd, eventStart) > 0;
}

export function isEventSlotAllDayEvent(slot) {
  const { eventStart, eventEnd } = slot;

  return (
    isSameMinute(eventStart, eventEnd) ||
    differenceInDays(startOfMinute(eventEnd), startOfMinute(eventStart)) > 0
  );
}

export function defaultSlotGetter(info) {
  let hour = info.getHours();
  let shouldShade = hour < 8 || hour >= 18;
  return { className: shouldShade ? "time-slot-time-zone-slot-shade" : "" };
}

export function defaultCustomDayLayout(params) {
  return overlap({ ...params, minimumStartDifference: 15 });
}

export function determineRBCLocalizer(startOfWeekIntger, dateFieldOrder) {
  // https://github.com/jquense/react-big-calendar/issues/1837
  return dateFnsLocalizer({
    format,
    parse,
    startOfWeek: () =>
      startOfWeek(new Date(), { weekStartsOn: startOfWeekIntger }),
    getDay,
    locales: determineLocale(dateFieldOrder),
  });
}

export function determineLocale(dateFormat) {
  if (dateFormat === MOMENT_DMY_DATE_FORMAT) {
    return { "en-GB": require("date-fns/locale/en-GB") };
  } else {
    return { "en-US": require("date-fns/locale/en-US") };
  }
}
