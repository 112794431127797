import {
  differenceInMinutes,
} from "date-fns";

export function shouldShowReducedHeightTransparentMergedEvent(event) {
  return (
    differenceInMinutes(event.eventEnd, event.eventStart) <= 30
  );
}

export function isEventBetween15And30Minutes(event) {
  let minDiff = differenceInMinutes(event.eventEnd, event.eventStart);
  return minDiff <= 30 && minDiff > 15;
}
