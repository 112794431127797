import React, { Component } from "react";
import VimcalLogoWithMessage from "./vimcalLogoWithMessage";

class CancelPersonalLink extends Component {
  render() {
    return (
      <div className="availability-error-container text-center">
        <div
          style={{
            fontSize: 18,
            fontWeight: 300,
            marginBottom: 70,
          }}
        >
          {this.props.message}
        </div>

        <VimcalLogoWithMessage />
      </div>
    );
  }
}

export default CancelPersonalLink;
