import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ErrorBoundary from "./js/components/ErrorBoundary";
import RedirectRoute from "./js/services/redirectRoute";
import AvailabilityLink from "./js/views/availabilityLink";
import CancelLink from "./js/views/cancelLink";

import "./js/styles/style.css";
import "./js/styles/default-styles.css";
import "./App.css";
import "./js/styles/buttons.css";
import "./js/styles/dnd_styles.css";
import "./js/styles/main_calendar.css";
import "./js/styles/monthlyCalendarStyle.css";
import "./js/styles/groupVoteLinkStyles.css";
import "./js/styles/groupVoteSpreadSheet.css";
import "./js/styles/override-styles.css";
import "./js/styles/web.css";
import "./js/styles/mobile.css";
import "./js/styles/overrideStyles.css";
import BackendContainer from "./js/components/backendContainer";

class App extends Component {
  render() {
    return (
      <Router>
        <ErrorBoundary>
          <div className="App">
            <BackendContainer />
            <Switch>
              <Route
                path="/t/:token"
                exact
                render={() => <AvailabilityLink />}
              />
              <Route
                path="/t/:username/:slug"
                render={() => <AvailabilityLink hasSlug={true} />}
              />
              <Route
                path="/p/:token"
                exact
                render={() => <AvailabilityLink isPersonalLink={true} />}
              />
              <Route
                path="/p/:username/:slug"
                render={() => (
                  <AvailabilityLink isPersonalLink={true} hasSlug={true} />
                )}
              />
              <Route path="/c/:token" exact component={CancelLink} />
              <Route
                path="/r/:appointmentToken"
                render={() => (
                  <AvailabilityLink isPersonalLink={true} isReschedule={true} />
                )}
              />
              <Route
                path="/rs/:token"
                render={() => <AvailabilityLink isReschedule={true} />}
              />
              <Route
                path="/g/:token"
                exact
                render={() => <AvailabilityLink isGroupVoteLink={true} />}
              />
              <Route
                path="/g/:username/:slug"
                render={() => (
                  <AvailabilityLink isGroupVoteLink={true} hasSlug={true} />
                )}
              />
              <Route
                path="/maestro"
                render={() => (
                  <AvailabilityLink
                    isMaestroSignUp={true}
                    isPersonalLink={true}
                  />
                )}
              />

              <Route
                path="/onboarding"
                render={() => (
                  <AvailabilityLink
                    isRegularOnboarding={true}
                    isPersonalLink={true}
                  />
                )}
              />

              <Route
                path="/sapphire"
                render={() => (
                  <AvailabilityLink isSapphire={true} isPersonalLink={true} />
                )}
              />
              <Route
                path="/gs/:username/:slug"
                render={() => (
                  <AvailabilityLink
                    isGroupVoteSpreadSheet={true}
                    hasSlug={true}
                  />
                )}
              />
              <Route
                path="/gs/:token"
                exact
                render={() => <AvailabilityLink isGroupVoteSpreadSheet={true} />}
              />

              <RedirectRoute path="*" />
            </Switch>
          </div>
        </ErrorBoundary>
      </Router>
    );
  }
}

export default App;
