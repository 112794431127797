import React from "react";
import ContactHandle from "./contactHandle";
import TelegramLogo from "../icons/telegramLogo";

export default function TelegramHandle() {
  return (
    <ContactHandle>
      <TelegramLogo size={14} />
    </ContactHandle>
  )
}
