import React from "react";
import ContactHandle from "./contactHandle";
import Link from "../icons/link";

export default function LinkHandle() {
  return (
    <ContactHandle>
      <Link size={13} className="pl-px" />
    </ContactHandle>
  )
}
