import React from "react";

function DisabledButton(props) {
  return (
    <div className="custom-button-box button-disabled select-none width-90px margin-right-10 text-white">
      {props.label || "Save"}
    </div>
  );
}

export default DisabledButton;
