import React from "react";
import Classnames from "classnames";

function Spinner(props) {
  return (
    <div
      className={Classnames(
        props.useSmallSpinner ? "small-spinner" : "spin",
        props.className
      )}
    ></div>
  );
}

export default Spinner;
