import { Component } from "react";
import { handleError } from "../services/commonUsefulFunctions";

class ErrorBoundary extends Component {
  componentDidCatch(err, info) {
    handleError(err, info);
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
