import { handleError } from "./commonUsefulFunctions";

const STORAGE_KEYS = {
  NAME: "name",
  EMAIL: "email",
};

function setLocalStorage({ key, value }) {
  try {
    localStorage?.setItem(key, value);
  } catch (error) {
    handleError(error);
  }
}

function getLocalStorage(key) {
  try {
    return localStorage?.getItem(key);
  } catch (error) {
    handleError(error);
  }
}

export function storeUserNameAndEmail({ email, name }) {
  setLocalStorage({ key: STORAGE_KEYS.EMAIL, value: email });
  setLocalStorage({ key: STORAGE_KEYS.NAME, value: name });
}

export function getStoredUserNameAndEmail() {
  return {
    storedEmail: getLocalStorage(STORAGE_KEYS.EMAIL) || "",
    storedName: getLocalStorage(STORAGE_KEYS.NAME) || "",
  };
}
