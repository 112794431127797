import classNames from "classnames";
import React from "react";

export default function VimcalVLogo({ className }) {
  return (
    <div className={classNames("secondary-text-fill", className || "")}>
      <svg
        width="28"
        height="22"
        viewBox="0 0 28 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.46494 4.37607C0.110796 5.81902 -0.3573 7.83964 0.860737 9.39149C1.71159 10.4755 3.06382 10.9343 4.33072 10.6972C9.48157 5.70127 14.4313 4.0788 18.2143 3.76016C18.7993 3.71089 19.3558 3.69283 19.8802 3.69817C20.9169 2.6006 22.0841 1.62107 23.3619 0.786108L23.3712 0.76269C23.3712 0.76269 17.8518 -0.523153 12.479 0.244326C7.78755 0.914467 3.15941 2.57066 1.46494 4.37607Z"
          // fill={color ?? "#ADB5BD"}
        />
        <path
          d="M5.27189 15.0112C4.2585 13.7201 4.27546 11.9488 5.20341 10.6886C10.0898 6.12923 14.7312 4.651 18.2643 4.3534L19.1897 4.46931C17.4613 6.50605 16.1562 8.89395 15.3849 11.4857C14.3678 11.7627 13.3635 12.2698 12.4091 12.9352C11.1532 13.8107 9.96724 14.9707 8.92682 16.2772C7.60829 16.5973 6.16433 16.1481 5.27189 15.0112Z"
          // fill={color ?? "#ADB5BD"}
        />
        <path
          d="M9.36035 16.6885C9.28812 16.8186 9.22322 16.9546 9.16637 17.0963C8.43156 18.9271 9.31995 21.007 11.1507 21.7419C11.7566 21.9851 12.3895 22.0506 12.9922 21.9629C13.5953 21.8783 14.1848 21.6382 14.6984 21.2351C14.8054 21.1511 14.9064 21.0622 15.0011 20.969C14.916 20.5614 14.8433 20.1485 14.7836 19.7305C14.4112 17.1234 14.577 14.5637 15.1966 12.1636C14.3765 12.4323 13.5538 12.8629 12.7496 13.4236C11.5342 14.2708 10.3775 15.4045 9.36035 16.6885Z"
          // fill={color ?? "#ADB5BD"}
        />
        <path
          d="M18.2643 4.3534C18.6344 4.32223 18.9927 4.30399 19.338 4.29655C19.2882 4.35385 19.2388 4.41144 19.1897 4.46931L18.2643 4.3534Z"
          // fill={color ?? "#ADB5BD"}
        />
      </svg>
    </div>
  );
}
