import React                                  from 'react';


function LoadingSkeleton(props) {
  return (
    <div
      className={`skeleton ${props.className || ""}`}
      style={Object.assign({},
        props.style)}
    >
    </div>
  );
}


export default LoadingSkeleton;
