const GOOGLE_CLIENT_ID =
  "800204084418-p9v6g9o2t9lklvr3bdssqd5p20c1hagu.apps.googleusercontent.com";
const HOME_LINK = "https://calendar.vimcal.com";
const GOOGLE_REDIRECT_URL = `${HOME_LINK}/login`;
const GOOGLE_LOGIN_SCOPES =
  "email profile https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/contacts.other.readonly https://www.googleapis.com/auth/admin.directory.resource.calendar.readonly https://www.googleapis.com/auth/admin.directory.user.readonly";
const GOOGLE_LOGIN_STATE = "google_login";

export function getGoogleLoginURL() {
  // doc: https://developers.google.com/identity/protocols/oauth2/web-server#authorization-errors-redirect-uri-mismatch
  // https://developers.google.com/identity/openid-connect/openid-connect
  const url =
    `https://accounts.google.com/o/oauth2/auth?` +
    `client_id=${encodeURIComponent(GOOGLE_CLIENT_ID)}` +
    `&redirect_uri=${encodeURIComponent(GOOGLE_REDIRECT_URL)}` +
    `&scope=${encodeURIComponent(GOOGLE_LOGIN_SCOPES)}` +
    `&prompt=${encodeURIComponent("consent select_account")}` +
    "&access_type=offline" +
    "&include_granted_scopes=true" +
    `&state=${GOOGLE_LOGIN_STATE}` +
    `&response_type=code`;

  return url;
}

/* The default scopes on backend */
export const OUTLOOK_DEFAULT_SCOPES = [
  "Calendars.ReadWrite",
  "Contacts.Read",
  "User.Read",
  "Calendars.ReadWrite.Shared",
  "User.ReadBasic.All",
];

/* Additional scopes */
/* Separate for backwards compatibility */
/* These are scopes that were added after the above */
export const OUTLOOK_ADDITIONAL_SCOPES = ["Mail.Send", "Mail.ReadWrite"];

export function getOutLookLoginURL() {
  const baseURL =
    "https://login.microsoftonline.com/common/oauth2/v2.0/authorize";
  const clientID = "client_id=6f69f169-45a2-4a4f-a12f-81c707de1f75";
  const redirectURL = `redirect_uri=${HOME_LINK}/login`;
  const scopes = [...OUTLOOK_DEFAULT_SCOPES, ...OUTLOOK_ADDITIONAL_SCOPES].join(
    "%20"
  );
  return `${baseURL}?${clientID}&response_type=code&${redirectURL}&response_mode=query&scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2F${scopes}&prompt=consent`;
}
