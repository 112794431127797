import React from "react";
import ContactHandle from "./contactHandle";
import { Youtube } from "react-feather";

export default function YouTubeHandle() {
  return (
    <ContactHandle>
      <Youtube size={14} />
    </ContactHandle>
  )
}
