import React from "react";
import ContactHandle from "./contactHandle";
import LinkedInLogo from "../icons/linkedInLogo";

export default function LinkedInHandle() {
  return (
    <ContactHandle>
      <LinkedInLogo size={12} className="pl-px" />
    </ContactHandle>
  )
}
