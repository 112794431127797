import React, { Component }                         from 'react';
import VimcalLogoWithMessage                        from "./vimcalLogoWithMessage";


class LoadingScreen extends Component {
  render() {
    return (
      <div
        className="cursor-pointer width-100-percent display-flex justify-content-center align-items-center position-relative"
      >
        <div style={{position: 'absolute', top: 200}}>
          <div className={"spin"}>
          </div>
        </div>

        <div style={{position: 'absolute', top: 380}}>
          <VimcalLogoWithMessage />
        </div>
      </div>
    );
  }
}

export default LoadingScreen;
