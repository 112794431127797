import React from "react";
import _ from "underscore";
import ProfilePhoto from "./profilePhoto";
import LinkedInHandle from "./contacts/linkedInHandle";
import TelegramHandle from "./contacts/telegramHandle";
import TwitterHandle from "./contacts/twitterHandle";
import YouTubeHandle from "./contacts/youTubeHandle";
import LinkHandle from "./contacts/linkHandle";
import GitHubHandle from "./contacts/gitHubHandle";

interface HostDetailsProps {
  hostDisplayName?: string
  profilePhotoSrc?: string
  socialLinks?: SocialLink[]
}

const SOCIAL_NETWORK_NAMES = {
  GITHUB: "github",
  LINKEDIN: "linkedin",
  TWITTER: "twitter",
  TELEGRAM: "telegram",
  YOUTUBE: "youtube",
  WEBSITE: "website",
};

const SOCIAL_LINK_HANDLE_COMPONENTS = {
  [SOCIAL_NETWORK_NAMES.GITHUB]: GitHubHandle,
  [SOCIAL_NETWORK_NAMES.LINKEDIN]: LinkedInHandle,
  [SOCIAL_NETWORK_NAMES.TELEGRAM]: TelegramHandle,
  [SOCIAL_NETWORK_NAMES.TWITTER]: TwitterHandle,
  [SOCIAL_NETWORK_NAMES.YOUTUBE]: YouTubeHandle,
};

export default function HostDetails({ hostDisplayName, profilePhotoSrc, socialLinks=[] }: HostDetailsProps) {
  return (
    <>
      {profilePhotoSrc ? <ProfilePhoto src={profilePhotoSrc} /> : null}
      {hostDisplayName
        ? <div className="flex flex-wrap items-center gap-x-3 gap-y-2 mb-1">
          <div className="availability-user-name leading-none pt-px">
            {hostDisplayName}
          </div>
          {socialLinks.length > 0  
            ? <div className="inline-flex gap-2 availability-social-links">
              {socialLinks.map(socialLink => {
                const HandleComponent = _.get(SOCIAL_LINK_HANDLE_COMPONENTS, socialLink.social_network_name, LinkHandle);
                return (
                  <a className="" key={socialLink.social_network_name} href={socialLink.url} target="_blank" rel="noreferrer">
                    <HandleComponent />
                  </a>
                );
              })}
            </div>
            : null}
        </div>
        : null}
    </>
  );
}
