import React from "react";
import Select from "react-select";
import { DEFAULT_FONT_COLOR } from "../services/globalVariables";

export default function CustomSelect({
  value,
  onChange,
  options,
  fontSize,
  controllerWidth,
  menuListStyle,
  menuListMaxHeight,
  filterOption,
  onInputChange,
}) {
  return (
    <Select
      value={value}
      onChange={onChange}
      options={options}
      isSearchable
      classNamePrefix="dark-mode-modal"
      className="select-region-code"
      maxMenuHeight={100}
      filterOption={filterOption}
      onInputChange={onInputChange}
      styles={{
        container: (base, state) => ({
          ...base,
          "&:hover": {
            backgroundColor: "transparent",
          },
          cursor: "pointer",
          fontSize: fontSize || "14px",
        }),
        singleValue: (base, state) => ({
          ...base,
          fontSize: fontSize || "14px",
        }),
        groupHeading: (base) => ({
          ...base,
          marginBottom: "8px",
        }),
        menuList: (base) => ({
          ...base,
          maxHeight: menuListMaxHeight ?? undefined,
        }),
        control: (base) => ({
          ...base,
          borderColor: "#ededed",
          "&:hover": {
            borderColor: DEFAULT_FONT_COLOR,
            backgroundColor: "transparent",
          },
          borderRadius: "7px",
          backgroundColor: "transparent",
          boxShadow: "initial",
          minHeight: "34px",
          width: controllerWidth || undefined,
        }),
        indicatorSeparator: (base) => ({
          display: "none",
        }),
        ...(menuListStyle && menuListStyle),
      }}
    />
  );
}
