import classNames from "classnames";
import React from "react";
import {
  getVimcalURLWithAttribution,
  OpenLink,
} from "../services/commonUsefulFunctions";
import VimcalHoverableLogo from "./logo/vimcalHoverableLogo";

export default function HoverableLogo({ containerClassName, hideTagLine }) {
  return (
    <div
      className={classNames(
        "font-size-14 flex flex-col items-center justify-center",
        "cursor-pointer",
        "hoverable-secondary-text-fill",
        "hoverable-secondary-text-color",
        "select-none",
        containerClassName ?? "mt-5"
      )}
      onClick={onClickLogo}
    >
      <VimcalHoverableLogo />

      {hideTagLine ? null : (
        <div className="mt-2 font-size-12">The world's fastest calendar</div>
      )}
    </div>
  );
}

function onClickLogo() {
  OpenLink(getVimcalURLWithAttribution());
}
