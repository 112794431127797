import React, { Component }                 from 'react';
import moment                               from 'moment';
import {
  ChevronLeft,
  ChevronRight
}                                           from 'react-feather';
import Calendar                             from 'react-calendar';
import {
  IsEmptyObject,
  isMac
}                                           from '../services/commonUsefulFunctions';
import {
  MEDIUM_GRAY,
  AVAILABLE_DAY_MOMENT_FORMAT,
  HOVER_BLUE,
  DEFAULT_BLUE,
  LIGHT_BLUE
}                                           from '../services/globalVariables';
import classNames from 'classnames';


const MONTHLY_NAVIGATION_ARROW_SIZE = "15";

class MonthlyCalendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDay: this.props.selectedDay ? moment(this.props.selectedDay).toDate() : moment().toDate(),
      hoverDate: null,
      isMac: isMac(),
      selectedMonth: moment()
    };

    this.onMouseLeaveDay = this.onMouseLeaveDay.bind(this);
    this.onMouseEnterDay = this.onMouseEnterDay.bind(this);
    this.onClickDay = this.onClickDay.bind(this);
    this.nextMonth = this.nextMonth.bind(this);
    this.previousMonth = this.previousMonth.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selectedDay !== this.props.selectedDay) {
      this.setState({selectedDay: moment(this.props.selectedDay).toDate()});
    }
  }

  render() {
    return (
      <div className={classNames("mt-2.5", "w-full flex justify-center", "max-h-full overflow-y-auto")}>
        <Calendar
          id="monthly-calendar"
          className='monthly-calendar availability-monthly-calendar'
          onClickDay={this.onClickDay}
          value={this.state.selectedDay}
          prevLabel={this.renderPreviousMonthButton()}
          nextLabel={this.renderNextMonthButton()}
          tileContent={({ date }) => this.renderDayTile(date)}
          formatShortWeekday={(locale, date) => this.getDayOfWeek(date)}
          formatMonthYear={(locale, date) => moment(date).format('MMM YYYY')}
          next2Label={null}
          prev2Label={null}
          showNavigation={true}
          showNeighboringMonth={false}
          defaultActiveStartDate={this.state.selectedMonth.toDate()}
          calendarType={'US'}
        />
      </div>
    );
  }

  renderHeader() {
    return (
      <div
        className="display-flex-flex-direction-row align-items-center justify-content-space-around user-select-none"
        style={{height: 60}}
      >
        {this.renderPreviousMonthButton()}

        <div className="availability-monthly-calendar-title">
          {moment(this.state.selectedMonth).format('MMM YYYY')}
        </div>

        {this.renderNextMonthButton()}
      </div>
    )
  }

  renderPreviousMonthButton() {
    return (
      <span>
        <ChevronLeft
          size={MONTHLY_NAVIGATION_ARROW_SIZE}
          className={"monthly-calendar-navigation-arrow"}
          onClick={this.previousMonth}
        />
      </span>
    );
  }

  renderNextMonthButton() {
    return (
      <span>
        <ChevronRight
          size={MONTHLY_NAVIGATION_ARROW_SIZE}
          className="monthly-calendar-navigation-arrow"
          onClick={this.nextMonth}
        />
      </span>
    );
  }

  renderDayTile(date) {
    return (
      <div
        onMouseEnter={() => this.onMouseEnterDay(date)}
        onMouseLeave={this.onMouseLeaveDay}
        className="monthly-calendar-day-tile-background"
      >
        <div
          style={this.tileDayStyle(date)}
          className='transition-monthly-agenda-date'
        >
          {moment(date).format('D')}
        </div>
      </div>
    );
  }

  getDayOfWeek(date) {
    return moment(date).format('ddd')[0];
  }

  onMouseEnterDay(date) {
    this.setState({hoverDate: moment(date).toDate()});
  }

  onMouseLeaveDay() {
    this.setState({hoverDate: null});
  }

  onClickDay(date) {
    if (this.shouldDateBeGrayedOut(date)) {
      return;
    }

    if (this.props.onClickDaySlot) {
      this.props.onClickDaySlot(moment(date).format(AVAILABLE_DAY_MOMENT_FORMAT));
    }

    this.setState({selectedDay: moment(date).toDate(), selectedMonth: moment(date)});
  }

  tileDayStyle(date) {
    const {color, backgroundColor} = this.determineTileStyle(date);

    return {
      borderRadius: '50%',
      height: 30,
      width: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: backgroundColor,
      color: color,
      fontSize: 12,
      fontWeight: '300',
      cursor: this.shouldDateBeGrayedOut(date) ? 'default' : null
    };
  }

  determineTileStyle(date) {
    let momentDate = moment(date);

    if (this.shouldDateBeGrayedOut(date)) {
      return {color: MEDIUM_GRAY, backgroundColor: 'transparent'};
    } else if (momentDate.isSame(moment(this.state.selectedDay), 'day')) {
      return {color: 'white', backgroundColor: DEFAULT_BLUE};
    } else if (momentDate.isSame(moment(this.state.hoverDate), 'day')) {
      return {color: "white", backgroundColor: HOVER_BLUE};
    } else {
      // clickable days
      return {color: DEFAULT_BLUE, backgroundColor: LIGHT_BLUE};
    }
  }

  isDateBeforeCurrentDay(date) {
    return moment(date).isBefore(moment(), "day");
  }

  shouldDateBeGrayedOut(date) {
    return this.isDateBeforeCurrentDay(date) || !this.isDateAvailable(date);
  }

  isDateAvailable(date) {
    return this.props.availableSlots && this.props.availableSlots[moment(date).format(AVAILABLE_DAY_MOMENT_FORMAT)];
  }

  nextMonth() {
    let updatedMonth = this.state.selectedMonth.clone().add(1, "month");

    this.setState({selectedMonth: updatedMonth});
  }

  previousMonth() {
    if (this.isPreviousMonthBeforeCurrentMonth()) {
      return;
    }

    let updatedMonth = this.state.selectedMonth.clone().subtract(1, "month");

    this.setState({selectedMonth: updatedMonth});
  }

  isPreviousMonthBeforeCurrentMonth() {
    return this.state.selectedMonth.clone().subtract(1, "month").isBefore(moment(), "month");
  }

  determineMaxMinAvailableDays() {
    if (IsEmptyObject(this.props.availableSlots)) {
      this.setState({maxDay: new Date(), minDay: new Date()});
    }

    let maxDay;
    let minDay;

    Object.keys(this.props.availableSlots).forEach(k => {
      if (!this.props.availableSlots[k]) {
        return;
      }

      let day = moment(k);

      if (!maxDay) {
        maxDay = day;
      } else if (moment(maxDay).isBefore(day,'day')) {
        maxDay = day;
      }

      if (!minDay) {
        minDay = day;
      } else if (moment(minDay).isAfter(day, 'day')) {
        minDay = day;
      }
    });

    this.setState({maxDay, minDay});
  }

  shouldDisablePreviousMonth() {
    return this.state.selectedMonth.clone().subtract(1, "month").isBefore(this.state.minDay, "month");
  }
}

export default MonthlyCalendar;
