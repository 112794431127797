import React                from 'react';


function ColoredLine(props) {
  return (
    <hr
      className={props.inputClassName ?? undefined}
      style={Object.assign({
        backgroundColor: '#E0E0E0',
        height: 1,
        width: props.width || '100%',
        border: 0
      }, props.style)}
    />
  );
}


export default ColoredLine;
