import React from "react";

import { type IconProps } from "react-feather";

export default function TelegramLogo({ color="currentColor", size=10 }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.9184 0.83457C12.7084 0.65649 12.3785 0.63101 12.0375 0.76793H12.0369C11.6782 0.91185 1.88385 5.11297 1.48513 5.28461C1.41261 5.30981 0.779249 5.54613 0.844489 6.07253C0.902729 6.54713 1.41177 6.74369 1.47393 6.76637L3.96397 7.61897C4.12917 8.16889 4.73817 10.1978 4.87285 10.6312C4.95685 10.9014 5.09377 11.2565 5.33373 11.3295C5.54429 11.4107 5.75373 11.3365 5.88925 11.2301L7.41161 9.81809L9.86917 11.7347L9.92769 11.7697C10.0946 11.8436 10.2544 11.8806 10.407 11.8806C10.5249 11.8806 10.6381 11.8584 10.7461 11.8142C11.1143 11.663 11.2616 11.3122 11.277 11.2724L13.1127 1.73085C13.2247 1.22125 13.069 0.96197 12.9184 0.83457ZM6.16001 7.96001L5.32001 10.2L4.48001 7.40001L10.92 2.64001L6.16001 7.96001Z" fill={color} />
    </svg>
  );
}
