import React from "react";
import ContactHandle from "./contactHandle";
import { GitHub } from "react-feather";

export default function GitHubHandle() {
  return (
    <ContactHandle>
      <GitHub size={12} className="full-attendee-list-attendee-handle" />
    </ContactHandle>
  );
}
