import React from "react";
import classNames from "classnames";

export default function ContactHandle({ children, className, ...props }: React.HTMLProps<HTMLDivElement>) {
  return (
    <div
      className={classNames("contact-handle flex items-center justify-center rounded-full h-6 w-6 cursor-pointer clickable-contact-handle", className)}
      {...props}
    >
      {children}
    </div>
  );
}
