import classNames from "classnames";
import React, { useRef } from "react";
import { X } from "react-feather";
import {
  hasStopEventPropagation,
  IsEmailValid,
  KEYCODE_COMMA,
  KEYCODE_DELETE,
  KEYCODE_ENTER,
  KEYCODE_TAB,
  removeDuplicatesFromArray,
  hasEventPreventDefault,
  KEYCODE_SPACE
} from "../services/commonUsefulFunctions";

const TEXT_AREA_ID = "add-attendee-container";
export default function AdditionalGuestsInput({ 
  onSetGuests, 
  guestEmails = [],
  guestInputText,
  setGuestInputText,
}) {
  const addEmail = (email) => {
    if (guestEmails.includes(email) || guestEmails.length >= 10) {
      return;
    }
    onSetGuests(removeDuplicatesFromArray([...guestEmails, email]));
  };

  const removeEmail = (email) => {
    onSetGuests(guestEmails.filter((guestEmail) => guestEmail !== email));
  };

  const onChange = (e) => {
    setGuestInputText(e.target.value);
  };

  const onKeyDown = (e) => {
    const {
      keyCode
    } = e;
    if (keyCode === KEYCODE_ENTER || keyCode === KEYCODE_TAB || keyCode === KEYCODE_COMMA || keyCode === KEYCODE_SPACE) {
      // add input value
      hasEventPreventDefault(e);
      const formattedInputValue = guestInputText.trim().toLowerCase();
      if (!formattedInputValue) {
        return;
      }
      addEmail(guestInputText);
      setGuestInputText("");
    } else if (e.keyCode === KEYCODE_DELETE && !guestInputText) {
      // remove last input value
      if (guestEmails.length === 0) {
        return;
      }

      removeEmail(guestEmails[guestEmails.length - 1]);
    }
  };

  const inputRef = useRef(null);

  const onFocusContainer = () => {
    inputRef.current.focus();
  };

  return (
    <div
      id={TEXT_AREA_ID}
      className="add-attendee-container"
      onClick={onFocusContainer}
    >
      {guestEmails.map((email, index) => {
        return (
          <span
            key={`guest-email-${index}}`}
            className={classNames(
              "guest-tag",
              IsEmailValid(email) ? "valid-email-tag" : "invalid-email-tag",
            )}
            type={"button"}
          >
            <span className="max-w-full truncate-text">{email}</span>

            <X
              onClick={() => removeEmail(email)}
              className="ml-1.5 cursor-pointer opacity-50 hover:opacity-100 duration-200"
              size={14}
            />
          </span>
        );
      })}
      <div 
        onClick={hasStopEventPropagation}
        className="w-full"
      >
        <input
          className="border-transparent font-size-14 font-weight-300 bg-transparent w-full max-w-full"
          value={guestInputText}
          onChange={onChange}
          ref={inputRef}
          onKeyDown={onKeyDown}
        />
      </div>
    </div>
  );
}
