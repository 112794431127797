import React, { Component } from "react";
import { AVAILABILITY } from "../services/googleCalendarService";
import { Check } from "react-feather";
import {
  shouldReduceTextSize,
  hasEventPreventDefault,
  hasStopEventPropagation,
  isMobileHorizontally,
  getEventLocation,
  getEventStatus,
} from "../services/commonUsefulFunctions";
import { format, differenceInMinutes } from "date-fns";
import classNames from "classnames";
import {
  shouldShowReducedHeightTransparentMergedEvent,
  isEventBetween15And30Minutes,
} from "../lib/eventFunctions";
import ScheduleCheckBox from "./groupVote/scheduleCheckBox";

class CustomEvent extends Component {
  constructor(props) {
    super(props);

    this._cachedEventInfo = {};
    this.onClickEvent = this.onClickEvent.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  // can't use hasStateOrPropsChanged here because weekly calendar sends down some props that aren't immutable
  shouldComponentUpdate(newProps, newState) {
    if (this.props.event.uniqueEtag !== newProps.event.uniqueEtag) {
      return true;
    } else if (this.props.event.eventStart !== newProps.event.eventStart) {
      return true;
    } else if (this.props.event.eventEnd !== newProps.event.eventEnd) {
      return true;
    } else if (
      this.props.event.summaryUpdatedWithVisibility !==
      newProps.event.summaryUpdatedWithVisibility
    ) {
      return true;
    } else if (newProps.event && newProps.event.start) {
      // This is where we determine drag on event
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._cachedEventInfo = null;
  }

  render() {
    // can't cache via etag because when you drag/resize events, this.props.event.start changes
    let startTime = this.createStartTime();
    let endTime = format(
      this.props.event.end || this.props.event.eventEnd,
      "h:mmaaa"
    );

    let {
      minutesDiffBetweenStartAndEnd,
      displayWarning,
      attendees,
      displaySummaryAndTimeAsOneLine,
      isEventAvailibilityEvent,
      location,
    } = this.determineEventInfo();

    return (
      <div
        id={this.props.event.user_event_id}
        key={`custom_event_${this.props.event.user_event_id}`}
        className={classNames(
          this.determineContainerHeight(),
          isEventAvailibilityEvent ? "position-relative" : "",
        )}
        onClick={this.onClickEvent}
      >
        {!this.isAvailabilityEvent() &&
          displaySummaryAndTimeAsOneLine &&
          this.renderSingleLineEvent(displayWarning, location)}

        {this.renderMultiLineEvent(
          startTime,
          endTime,
          minutesDiffBetweenStartAndEnd,
          displayWarning,
          attendees,
          displaySummaryAndTimeAsOneLine,
          location
        )}
      </div>
    );
  }

  renderSingleLineEvent(displayWarning, location) {
    let singleLineClassName = shouldReduceTextSize(this.props.event)
      ? "font-size-10 mt-0"
      : "";

    if (this.props.event.displayAsAllDay) {
      singleLineClassName = singleLineClassName + " margin-left-1";
    }

    return (
      <div
        className={classNames(
          "custom-event-display-single-line-event",
          singleLineClassName,
          isEventBetween15And30Minutes(this.props.event) ? "pt-0.5" : "",
          this.props.event?.displayAsAllDay ? "padding-top-1" : ""
        )}
      >
        <span className={"font-weight-400 letter-spacing-0-3"}>
          {this.props.event.summaryUpdatedWithVisibility}
        </span>

        {!this.props.event.displayAsAllDay && (
          <span className="margin-right-2">,</span>
        )}

        {!this.props.event.displayAsAllDay && (
          <span
            className={classNames(
              "custom-event-display-single-line-event",
              "font-weight-300",
              singleLineClassName
            )}
          >
            {format(
              this.props.event.start || this.props.event.eventStart,
              "h:mmaaa"
            )}
          </span>
        )}

        {location && (
          <span>
            <span className="margin-right-5">,</span>

            <span className="font-weight-300">{location}</span>
          </span>
        )}
      </div>
    );
  }

  renderMultiLineEvent(
    startTime,
    endTime,
    minutesDiffBetweenStartAndEnd,
    displayWarning,
    attendees,
    displaySummaryAndTimeAsOneLine,
    location
  ) {
    if (
      this.props.event.displayGroupVoteInfo &&
      (minutesDiffBetweenStartAndEnd <= 45 && !this.props.event.displayAsAllDay)
    ) {
      return this.renderCheckBox(
        displaySummaryAndTimeAsOneLine && minutesDiffBetweenStartAndEnd < 30
      );
    }

    const determineTimeLabel = () => {
      if (this.props.event.displayAsAllDay && this.isAvailabilityEvent()) {
        return 'All day';
      } else {
        return `${startTime} - ${endTime}`;
      }
    }

    return (
      <div className="padding-left-3">
        {!displaySummaryAndTimeAsOneLine && (
          <div
            className={classNames("custom-event-thirty-min-or-more-container")}
            style={{
              maxHeight: 16 * Math.floor(minutesDiffBetweenStartAndEnd / 30),
            }}
          >
            <span
              className={classNames(
                "letter-spacing-0-3",
                this.shouldAddAdditionalPadding(
                  this.props.event.summaryUpdatedWithVisibility
                )
                  ? "pl-0.5"
                  : ""
              )}
            >
              {this.props.event.summaryUpdatedWithVisibility}
            </span>
          </div>
        )}

        {(!displaySummaryAndTimeAsOneLine ||
          this.isAvailabilityEvent()) && (
          <div className="custom-event-thirty-min-or-more-display-time text-dark-mode-background-color">
            {determineTimeLabel()}
          </div>
        )}

        {location &&
          !displaySummaryAndTimeAsOneLine &&
          this.renderLocation(location)}

        {this.renderCheckBox()}
      </div>
    );
  }

  renderLocation(location) {
    return <div className="font-weight-300">{location}</div>;
  }

  renderCheckBox(isInReduceSpace = false) {
    if (!this.props.event.displayGroupVoteInfo) {
      return null;
    }

    const {
      displayAsAllDay
    } = this.props.event;

    return (
      <div
        className={classNames(
          "w-full flex flex-row items-center",
          isInReduceSpace || displayAsAllDay ? "" : "mt-1"
        )}
      >
        {this.props.event.hideCheckBox ? null : (
          <ScheduleCheckBox
            isChecked={this.props.event.isChecked}
            size="w-4 h-4"
          />
        )}
        {isMobileHorizontally() ? null : (
          <div
            className={classNames(
              "ml-1.5 font-weight-300 flex items-center",
              isInReduceSpace ? "font-size-12" : "font-size-14",
              "text-dark-mode-background-color"
            )}
          >
            {this.props.event.hideSlotCount ? null : (
              <>
                <Check size={isInReduceSpace ? 14 : 16} className="mr-0.5" />
                <div className="h-4">{this.props.event.slotCount}</div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }

  //================
  // EVENT HANDLERS
  //================

  shouldDisplayEventAsOneLine(minutesDiffBetweenStartAndEnd) {
    if (this.props.event.start && !this.props.event.displayAsAllDay) {
      return (
        differenceInMinutes(this.props.event.end, this.props.event.start) <= 30
      );
    } else {
      return (
        minutesDiffBetweenStartAndEnd <= 30 ||
        !!this.props.event.displayAsAllDay
      );
    }
  }

  //=================
  // PRIVATE METHODS
  //=================

  createStartTime() {
    let start = this.props.event.start || this.props.event.eventStart;
    let end = this.props.event.end || this.props.event.eventEnd;

    if (format(start, "aaa") !== format(end, "aaa")) {
      // am and pm do not match
      return format(start, "h:mmaaa");
    } else {
      return format(start, "h:mm");
    }
  }

  onClickEvent(e) {
    if (this.props.event.onClickEvent) {
      hasEventPreventDefault(e);
      hasStopEventPropagation(e);
      this.props.event.onClickEvent(this.props.event);
    }
  }

  createLocationText(event) {
    const location = getEventLocation(event);
    if (!location) {
      return null;
    }

    if (location.length === 0) {
      return null;
    }

    let splitText = location.split(",");
    return splitText[0];
  }

  determineEventInfo() {
    if (
      !!this.props.event.uniqueEtag &&
      !!this._cachedEventInfo[this.props.event.uniqueEtag]
    ) {
      return this._cachedEventInfo[this.props.event.uniqueEtag];
    }

    let minutesDiffBetweenStartAndEnd = differenceInMinutes(
      this.props.event.eventEnd,
      this.props.event.eventStart
    );

    let displaySummaryAndTimeAsOneLine = this.shouldDisplayEventAsOneLine(
      minutesDiffBetweenStartAndEnd
    );

    let isEventAvailibilityEvent =
      this.isAvailabilityEvent();
    let location = this.createLocationText(this.props.event);

    let result = {
      minutesDiffBetweenStartAndEnd,
      displaySummaryAndTimeAsOneLine,
      isEventAvailibilityEvent,
      location,
    };

    if (this.props.event.uniqueEtag) {
      this._cachedEventInfo[this.props.event.uniqueEtag] = result;
    }

    return result;
  }

  isAvailabilityEvent() {
    return getEventStatus(this.props.event) === AVAILABILITY;
  }

  shouldAddAdditionalPadding(title) {
    return title && (title[0] === "j" || title[0] === "J");
  }

  determineContainerHeight() {
    if (
      differenceInMinutes(
        this.props.event.eventEnd,
        this.props.event.eventStart
      ) <= 15
    ) {
      return "custom-event-container-15-min";
    }

    return shouldShowReducedHeightTransparentMergedEvent(this.props.event)
      ? "custom-event-reduced-height"
      : "regular-custom-event-container";
  }
}

export default CustomEvent;
