import { format, subDays } from "date-fns";
import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  pluralize,
  isMobileHorizontally,
  generateLargeRandomNumber,
  hasStopEventPropagation,
  stopNativeEventPropagation,
  isSafari,
} from "../../services/commonUsefulFunctions";
import classNames from "classnames";
import "../../styles/groupVoteLinkStyles.css";
import "../../styles/groupVoteDarkModeStyles.css";
import { Check } from "react-feather";
import ScheduleCheckBox from "./scheduleCheckBox";
import SchedulingCheckMark from "./schedulingCheckMark";
import {
  getAttendees,
  createKeyFromSlot,
  determineSlotAttendeeIndex,
  isSameSlot,
  parseEventsWithDefaultTimeZone,
  sortSlotsChronologically,
  getAttendeeNameAndEmailKey,
  isMultiDaySlot,
} from "../../lib/availabilityFunctions";
import _ from "underscore";
import { isEventSlotAllDayEvent } from "../../lib/rbcFunctions";
import { SECOND_IN_MS } from "../../services/globalVariables";

const HEADER_HEIGHT = "group-vote-date-time-header";
const MARKED_GREEN_BACKGROUND = "bg-green-100";
const DID_NOT_MARK_BACKGROUND = "bg-gray-50";
const NEW_ATTENDEE_SELECTED_COLOR = "bg-green-50 group-vote-selected-green-text";

/*
need backend to pass back objects like this:
{
	title,
	duration,
	description,
	conferencing,
	google_calendar_id for v1 and calendar_provider_id for v2,
	location,
	token //* similar to how we create token for slots,
	selected_slots: [{start, end}],
	attendees: [
		{
			name,
			email,
			slots: [
				{
					start, 
					end,
					response: "accepted" or "tenative"
				},
				...
			]
		},
		...
	]
}
*/

export default function GroupVoteSchedulingTable({
  bookingLink,
  newAttendeeSlots,
  setNewAttendeeSlot,
  newUserName,
  onChangeUserName,
  selectedTimeZone,
  resetSelectedSlots,
}) {
  const selectedTimeZoneRef = useRef(selectedTimeZone);
  const [attendeeList] = useState(getAttendees(bookingLink));
  const [slotAttendeeIndex] = useState(determineSlotAttendeeIndex(bookingLink));
  const [availableTimes, setAvailableTimes] = useState(
    sortSlotsChronologically(parseEventsWithDefaultTimeZone(bookingLink, selectedTimeZone))
  );
  // const [newAttendeeSlots, setNewAttendeeSlot] = useState([]);
  const isMobileLayout = useRef(isMobileHorizontally());
  const [rerenderCount, setRerenderCount] = useState(0);
  const [hideAttendees] = useState(bookingLink.anonymous);

  const handleResize = () => {
    if (isMobileHorizontally() !== isMobileLayout.current) {
      isMobileLayout.current = isMobileHorizontally();
      setRerenderCount(generateLargeRandomNumber(3));
    }
  };

  useEffect(() => {
    if (selectedTimeZoneRef.current === selectedTimeZone) {
      return;
    }
    selectedTimeZoneRef.current = selectedTimeZone;
    setAvailableTimes(sortSlotsChronologically(parseEventsWithDefaultTimeZone(bookingLink, selectedTimeZone)));
    if (resetSelectedSlots) {
      resetSelectedSlots();
    }
  }, [selectedTimeZone]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    setTimeout(() => {
      if (isSafari() && availableTimes?.length > 0) {
        const lastSlot = availableTimes?.[availableTimes.length - 1];
        // select and disselect
        // safari doesn't calculate the overflow well
        if (lastSlot) {
          setNewAttendeeSlot(lastSlot);
          setTimeout(() => {
            setNewAttendeeSlot(lastSlot);
          }, 0.01 * SECOND_IN_MS);
        }
      }
    }, 0);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderLeftAttendeesSection = () => {
    return (
      <aside
        className={classNames(
          "group-vote-link-border-right",
          "group-vote-attendees-container"
        )}
      >
        {isMobileLayout.current ? null : (
          <div
            className={classNames(
              HEADER_HEIGHT,
              "group-vote-link-border-bottom"
            )}
          ></div>
        )}
        {hideAttendees ? null : (
          <div className="participant-container font-weight-400 font-size-12-important">{`${
            attendeeList.length
          } ${pluralize(attendeeList.length, "attendee")}`}</div>
        )}

        <div
          className={classNames(
            "participant-container group-vote-link-border-top",
            "pr-2.5",
            "group-vote-input-container",
            isMobileLayout.current ? "justify-content-center" : "",
            "group-vote-selector-background-color",
            "padding-left-10px-important"
          )}
        >
          <input
            autoFocus={true}
            value={newUserName}
            onChange={onChangeUserName}
            placeholder={"Enter your name"}
            autoComplete="new-password"
            className={classNames(
              "group-vote-name-input mr-3",
              "font-size-12",
              isMobileLayout.current ? "w-10/12" : "w-44"
            )}
          />
        </div>
        {!isMobileLayout.current
          ? attendeeList.map((a, index) => {
              return (
                <div
                  key={`group-vote-attendee-${index}`}
                  className="participant-container group-vote-link-border-top pr-2.5"
                >
                  <div className="truncate-text max-width-160px font-size-12">
                    {a.name}
                  </div>
                </div>
              );
            })
          : null}
      </aside>
    );
  };

  const renderDateHeader = (slot) => {
    const {
      month,
      dayOfMonth,
      dayOfWeek,
      endMonth,
      endDayOfMonth,
      endDayOfWeek,
    } = getDateDetails(slot);
    if (isMobileLayout.current) {
      if (isMultiDaySlot(slot)) {
        return (
          <div className="flex items-center w-full justify-center">
            <div className="flex items-center flex-col">
              <div className="mt-2 font-size-14">{month}</div>
              <div className="font-size-14 font-weight-400">{dayOfMonth}</div>
              <div className="font-size-12 font-weight-300">
                {dayOfWeek.toUpperCase()}
              </div>
            </div>

            <div className="mx-1.5">-</div>

            <div className="flex items-center flex-col">
              <div className="mt-2 font-size-14">{endMonth}</div>
              <div className="font-size-14 font-weight-400">
                {endDayOfMonth}
              </div>
              <div className="font-size-12 font-weight-300">
                {endDayOfWeek.toUpperCase()}
              </div>
            </div>
          </div>
        );
      }

      return (
        <div className={classNames("flex flex-col items-center")}>
          <div className="font-size-14">{month}</div>
          <div className="font-size-14 font-weight-400">{dayOfMonth}</div>
          <div className="font-size-12">{dayOfWeek.toUpperCase()}</div>
        </div>
      );
    } else if (isMultiDaySlot(slot)) {
      return (
        <div className="flex items-center w-full justify-center">
          <div className="flex items-center flex-col">
            <div className="mt-2 font-size-14">{month}</div>
            <div className="font-size-14 font-weight-400">{dayOfMonth}</div>
            <div className="font-size-12 font-weight-300">
              {dayOfWeek.toUpperCase()}
            </div>
          </div>

          <div className="mx-1.5">-</div>

          <div className="flex items-center flex-col">
            <div className="mt-2 font-size-14">{endMonth}</div>
            <div className="font-size-14 font-weight-400">{endDayOfMonth}</div>
            <div className="font-size-12 font-weight-300">
              {endDayOfWeek.toUpperCase()}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={classNames("flex flex-col items-center")}>
          <div className="mt-2 font-size-14">{month}</div>
          <div className="font-size-14 font-weight-400">{dayOfMonth}</div>
          <div className="font-size-12 font-weight-300">
            {dayOfWeek.toUpperCase()}
          </div>
        </div>
      );
    }
  };

  const renderTime = (slot) => {
    const { startTime, endTime } = getTimeDetails(slot);
    const isAllDay = isEventSlotAllDayEvent(slot);
    return (
      <div
        className={classNames(
          isMobileLayout.current
            ? "display-flex flex-direction-row"
            : "display-flex flex-direction-column justify-content-center",
          "font-size-12",
          isMobileLayout.current ? "" : "mt-2.5"
        )}
      >
        <div
          className={classNames(
            "display-flex justify-content-center",
            isMobileLayout.current ? "margin-right-10" : ""
          )}
        >
          {isAllDay ? "All day" : startTime}
        </div>
        <div className="display-flex justify-content-center">
          {isAllDay ? "" : endTime}
        </div>
      </div>
    );
  };

  const renderCount = (slot, index) => {
    if (hideAttendees) {
      return null;
    }
    const determineVoteCountText = () => {
      if (!isMobileLayout.current) {
        return "";
      }

      if (slotAttendeeIndex[key]?.length === 1) {
        return " vote";
      }

      return " votes";
    };

    const key = createKeyFromSlot(slot, selectedTimeZone);
    const TEXT_COLOR = "text-blue-600";

    return (
      <div
        className={classNames(
          "flex items-center",
          isMobileLayout.current ? "mt-2" : "justify-center time-slot-height",
          "cursor-pointer",
          "relative"
        )}
      >
        {/* {renderHoverAttendeeInfo()} */}
        <Check
          className={classNames("mr-1", TEXT_COLOR)}
          size={16}
          strokeWidth={4}
        />
        <div
          className={classNames(TEXT_COLOR, "font-weight-400", "font-size-14")}
        >
          {`${slotAttendeeIndex[key]?.length || 0}${determineVoteCountText()}`}
        </div>
      </div>
    );
  };

  const hasAttendeeSelectedSlot = (slot) => {
    return newAttendeeSlots.some((s) => {
      return isSameSlot(s, slot);
    });
  };

  const renderNewUserMark = (slot, disableClick) => {
    // where new user marks which times work for him/her
    const hasSelectedSlot = hasAttendeeSelectedSlot(slot);

    const onChangeAttendeeSlot = (e) => {
      stopNativeEventPropagation(e);
      hasStopEventPropagation(e);
      setNewAttendeeSlot(slot);
    };

    return (
      <ScheduleCheckBox
        onChange={disableClick ? _.noop : onChangeAttendeeSlot}
        isChecked={hasSelectedSlot}
        defaulBorder={
          isMobileLayout.current
            ? "border-gray-200 hover:border-blue-300 duration-100"
            : ""
        }
      />
    );
  };

  const renderNonMobileSlotSection = (slot, index) => {
    const onClickBox = (e) => {
      hasStopEventPropagation(e);
      setNewAttendeeSlot(slot);
    };

    return (
      <li
        key={`slots-container-${index}`}
        className={classNames(
          "slot-container",
          isMultiDaySlot(slot) ? "slot-container-multi-day-event" : "",
          index === 0 ? "" : "group-vote-link-border-left"
        )}
      >
        <div
          className={classNames(
            "group-vote-link-border-bottom",
            HEADER_HEIGHT,
            hasAttendeeSelectedSlot(slot) ? getSelectedBackgroundColor() : ""
          )}
        >
          {renderDateHeader(slot)}
          {renderTime(slot)}
        </div>
        {renderCount(slot, index)}
        <div
          className={classNames(
            "group-vote-link-border-top participant-mark-container group-vote-input-container",
            "group-vote-selector-background-color",
            "cursor-pointer hover:bg-blue-300 duration-200"
          )}
          onClick={onClickBox}
        >
          {renderNewUserMark(slot, true)}
        </div>

        {hideAttendees
          ? null
          : attendeeList.map((a, attendeeIndex) => {
              const isMarked = hasAttendeeMarkedTime({
                attendee: a,
                slot,
                slotAttendeeIndex,
                selectedTimeZone,
              });
              return (
                <div
                  key={`attendee-booking-mark-${attendeeIndex}-${index}`}
                  className={classNames(
                    "participant-mark-container group-vote-link-border-top",
                    isMarked ? MARKED_GREEN_BACKGROUND : DID_NOT_MARK_BACKGROUND
                  )}
                >
                  {isMarked ? <SchedulingCheckMark /> : null}
                </div>
              );
            })}
      </li>
    );
  };

  const getSelectedBackgroundColor = () => {
    return NEW_ATTENDEE_SELECTED_COLOR;
  };

  const renderMobileSlotSection = (slot, index) => {
    const onChangeAttendeeSlot = (e) => {
      stopNativeEventPropagation(e);
      hasStopEventPropagation(e);
      setNewAttendeeSlot(slot);
    };

    const isMultiDay = isMultiDaySlot(slot);
    return (
      <li
        key={`slots-container-${index}`}
        className={classNames(
          "slot-container",
          isMultiDay && !isMobileLayout.current
            ? "slot-container-multi-day-event"
            : "",
          "group-vote-link-border-top",
          "align-items-center",
          hasAttendeeSelectedSlot(slot) ? getSelectedBackgroundColor() : "",
          isMultiDay && isMobileLayout.current
            ? "padding-left-0px-important"
            : ""
        )}
        onClick={onChangeAttendeeSlot}
      >
        <div className="display-flex align-items-center">
          {renderDateHeader(slot)}
          <div
            className={classNames(
              isMultiDay && isMobileLayout.current ? "ml-2" : "ml-6"
            )}
          >
            {renderTime(slot)}
            {renderCount(slot, index)}
          </div>
        </div>

        {renderNewUserMark(slot)}
      </li>
    );
  };

  const timeTable = useMemo(() => {
    // only call render here if available times or new times are picked
    // more performant
    return (
      <ul className="group-vote-time-container">
        {availableTimes.map((t, index) => {
          return isMobileLayout.current
            ? renderMobileSlotSection(t, index)
            : renderNonMobileSlotSection(t, index);
        })}
      </ul>
    );
  }, [availableTimes, newAttendeeSlots, isMobileLayout.current]);

  return (
    <div
      className={classNames(
        "group-vote-link-container",
        isMobileLayout.current ? "w-full" : ""
      )}
    >
      {renderLeftAttendeesSection()}
      {timeTable}
    </div>
  );
}

function getTimeDetails(slot) {
  const { eventStart, eventEnd } = slot;

  return {
    startTime: format(eventStart, "p"),
    endTime: format(eventEnd, "p"),
  };
}

function getDateDetails(slot) {
  const { eventStart, eventEnd } = slot;

  if (isMultiDaySlot(slot)) {
    const updatedEnd = subDays(eventEnd, 1);
    return {
      month: format(eventStart, "MMM"),
      dayOfMonth: format(eventStart, "d"),
      dayOfWeek: format(eventStart, "E"),
      endMonth: format(updatedEnd, "MMM"),
      endDayOfMonth: format(updatedEnd, "d"),
      endDayOfWeek: format(updatedEnd, "E"),
    };
  }

  return {
    month: format(eventStart, "MMM"),
    dayOfMonth: format(eventStart, "d"),
    dayOfWeek: format(eventStart, "E"),
    endMonth: format(eventEnd, "MMM"),
    endDayOfMonth: format(eventEnd, "d"),
    endDayOfWeek: format(eventEnd, "E"),
  };
}

function hasAttendeeMarkedTime({ attendee, slot, slotAttendeeIndex, selectedTimeZone }) {
  const key = createKeyFromSlot(slot, selectedTimeZone);
  return slotAttendeeIndex[key]?.includes(getAttendeeNameAndEmailKey(attendee));
}
