export function createUniqueZoomDescription(zoomData) {
  const { join_url, password, id, settings } = zoomData;

  return [
    "Join Zoom Meeting",
    join_url,
    `Meeting ID: ${id}`,
    `Password: ${password}`,
    getZoomOneTapDial(zoomData),
    getZoomGlobalDialInNumbers(settings?.global_dial_in_numbers),
    `Meeting ID: ${id}`,
    "Find your local number: https://us06web.zoom.us/u/kcac9diqY",
  ].join("<br/><br/>");
}

function getZoomOneTapDial({ id, password, settings = {} }) {
  const { global_dial_in_numbers } = settings;

  if (!global_dial_in_numbers || global_dial_in_numbers.length === 0) {
    return "";
  }

  //only show 2 one tap dials
  return global_dial_in_numbers.slice(0, 2).reduce((oneTapDial, dialIn) => {
    const { city, country, number } = dialIn;

    oneTapDial += `${number.replace("+", "")},,${id},,,,*${password}`;
    oneTapDial += `# ${country}${city ? ` (${city}` : ""}<br/>`;

    return oneTapDial;
  }, "One tap mobile<br/>");
}

function getZoomGlobalDialInNumbers(dialInArray = []) {
  return dialInArray.length === 0
    ? ""
    : dialInArray.reduce((result, dialIn) => {
        const { city, country, number } = dialIn;
        return result + `${number} ${country} ${city ? ` (${city}` : ""}<br/>`;
      }, "Dial by location<br/>");
}
