import { useEffect, useRef } from "react";

// https://gist.github.com/jaydenseric/a67cfb1b809b1b789daa17dfe6f83daa
export function useIsMounted() {
  // component is certainly mounted from the beginning
  const componentIsMounted = useRef(true);
  useEffect(() => {
    // when non-SSR + (ComponentDidMount or ComponentDidUpdate):
    // do nothing.
    // when non-SSR + ComponentWillUnmount:
    return () => {
      componentIsMounted.current = false;
    };
  }, []);
  return componentIsMounted;
}
