import React, {Component}             from 'react';
import {
  Calendar,
  Globe
}                                     from 'react-feather';
import {
  AddAbbrevationToTimeZone,
  guessTimeZone
}                                      from '../services/commonUsefulFunctions';


class TimeAndTimeZoneText extends Component {
  render() {
    return (
      <>
        <div
          className={`display-flex-flex-direction-row mt-2.5 font-size-14 items-center font-weight-300`}
          style={{textDecoration: this.props.shouldCrossThrough ? "line-through" : null}}
        >
          <div className="margin-right-10 display-flex items-center">
            <Calendar size={16} className={this.props.isNewTime ? "color-new-time" : ""} />
          </div>

          <div className={this.props.isNewTime ? "color-new-time" : ""}>
            {this.props.time}
          </div>
        </div>

        <div
          className={`display-flex-flex-direction-row mt-2.5 font-size-14 items-center font-weight-300`}
          style={{textDecoration: this.props.shouldCrossThrough ? "line-through" : null}}
        >
          <div className="margin-right-10 display-flex">
            <Globe size={16} />
          </div>

          <div>
            {AddAbbrevationToTimeZone(guessTimeZone())}
          </div>
        </div>
      </>
    );
  }
}

export default TimeAndTimeZoneText;
